/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import { Grid, Alert, LinearProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import RichTextEditor, { EditorValue } from 'react-rte';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { REQUIRED_VALIDATION } from '../../forms/_predefinedValidations';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate } from 'react-router-dom';
import { EMPTY_HTML, TOOLBAR_CONFIG } from '../../utils/editorConfig';
import { ShareContentRecipientDialog } from '../minidialogs/ShareContentRecipientDialog';
import PortalManagerRecipient from './PortalManagerRecipient';

interface IShareContentPageProps {
  children?: JSX.Element;
  title: string;
  buttonTitle?: string;
  loading?: boolean;
  error?: string;
  onSubmit: (data: any) => void;
}

export function ShareContentPage(props: IShareContentPageProps): JSX.Element {
  const { error, loading, title, buttonTitle, onSubmit } = props;
  const navigate = useNavigate();
  const { control, formState: { isValid }, setValue, getValues } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [selectedMerchants, setSelectedMerchants] = useState<string[]>([]);
  const [selectedPortalManagers, setSelectedPortalManagers] = useState<string[]>([]);

  const onEditorChange = (val: EditorValue) => {
    setHtml(val);
    setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
  };

  const onMerchantChange = (ids: string[]) => {
    setSelectedMerchants(ids);
  };

  const onPortalManagerChange = (ids: string[]) => {
    setSelectedPortalManagers(ids);
  };

  const submit = (e: any) => {
    e.preventDefault();
    const body = getValues();
    onSubmit({
      ...body,
      MerchantRecipientIds: selectedMerchants,
      PortalManagerRecipientIds: selectedPortalManagers
    });
  };

  return (
    <>
      <FormPageComponent
        hasError={!!error}
        title={title}
        buttonTitle={buttonTitle || 'Send Email'}
        buttonDisabled={!isValid || loading || (selectedMerchants.length === 0 && selectedPortalManagers.length === 0)}
        buttonLoading={loading}
        close={() => navigate(-1)}
        submit={submit}>
        {loading
          ? <LinearProgress variant="indeterminate" color="secondary" sx={{ zIndex: 2 }} />
          : <></>
        }
        <Grid item sx={{ flexGrow: 1 }}>
          {!!error
            && <Alert severity="error">{error}</Alert>
          }
        </Grid >
        <ModalCardComponent>
          <SectionHeader title="Email details" />
          <Grid item container rowSpacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <FormControlInput
                data-testid="send-email-subject"
                control={control}
                field={{
                  label: 'Subject',
                  name: 'subject',
                  inputType: 'text',
                  validation: {
                    maxLength: {
                      value: 50,
                      message: 'Subject should be up to 50 characters.',
                    },
                    required: REQUIRED_VALIDATION,
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                {...control.register('body', {
                  required: REQUIRED_VALIDATION,
                  validate: val => val !== EMPTY_HTML
                })}
                value={html}
                placeholder="Body"
                toolbarConfig={TOOLBAR_CONFIG}
                onChange={onEditorChange}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        <ShareContentRecipientDialog title="Merchant Recipients" onChange={onMerchantChange} />
        <PortalManagerRecipient
          title="Sales & Territory Manager Recipients"
          onChange={onPortalManagerChange} />
      </FormPageComponent >
    </>
  );
}
