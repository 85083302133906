/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import { IStyles } from '../../constants/theme';
import { BodyTwoPrimary } from '../../components-atoms/TypographyComponents';
import { IconButton } from '@mui/material';
import { MiniCard, MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { SearchbarWithDeboubce } from '../../components-molecules/SearchBarWithDebounce';
import SearchIcon from '@mui/icons-material/Search';

interface ISalesManagerModalProps {
  salesManagers: any;
  open: boolean;
  close: () => void;
  submit: (list: any) => void;
  onSearchChange?: (text: string) => void;
  selected?: any;
}

export function SalesManagerModal({salesManagers, open, close, submit, selected, onSearchChange }: ISalesManagerModalProps): JSX.Element {
  const [selectedItems, setSelectedItems] = useState(selected);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowSearchInput(false);
      setSearchQuery('');
    }
  }, [open]);

  useEffect(() => {
    setSelectedItems(selected);
  }, [selected]);

  const textOnChange = (text: string) => {
    setSearchQuery(text);
    onSearchChange?.(text);
  };

  const onSelect = (id: string) => {
    const found = selectedItems.find((s: any) => s.Id === id);
    const selected = found?.Id?.length > 0;
    if (selected) {
      const filtered = selectedItems.filter((s: any) => s.Id !== id);
      setSelectedItems(filtered);
    } else {
      const item = salesManagers.find((sm: any) => sm.SalesManager.AdminProperties.Id === id);
      setSelectedItems([...selectedItems, { ...item?.SalesManager.AdminProperties, LinkType: 'Store' }]);
    }
  };

  return (
    <MiniDialog
      title={'Select Sales Manager'}
      open={open}
      close={close}
      submit={() => submit(selectedItems)}>
      <>
        <div style={styles.dialogButtonContainer}>
          <div style={styles.dialogInnerButtonContainer}>
            <IconButton aria-label="search" sx={styles.sortFilterButton} onClick={() => setShowSearchInput(!showSearchInput)}>
              <SearchIcon color={'primary'} />
            </IconButton>
          </div>
        </div>
        <div style={styles.searchContainer}>
          <SearchbarWithDeboubce
            isOpen={showSearchInput}
            query={searchQuery}
            placeholder='Search Sales Manager'
            onCancel={() => {
              textOnChange('');
              setShowSearchInput(false);
            }}
            onChange={textOnChange}
          />
        </div>

        {(salesManagers && salesManagers?.length > 0
          ? salesManagers.map((sm: any) => {
            const id = sm.SalesManager.AdminProperties.Id;
            const found = selectedItems.find((s: any) => s.Id === id);
            const selected = found?.Id?.length > 0;
            return (
              <MiniCard
                multiple
                id={id}
                key={id}
                title={`${sm.SalesManager.AdminProperties.FirstName} ${sm.SalesManager.AdminProperties.LastName}`}
                subtitle={sm.SalesManager.AdminProperties.Email}
                selected={selected}
                disabled={found && found.LinkType !== 'Store'}
                onClick={onSelect}
              />
            );
          })
          : <BodyTwoPrimary sx={{ padding: '16px 24px 0' }}>0 results found.</BodyTwoPrimary>
        )}
      </>
    </MiniDialog>
  );
}

const styles: IStyles = {
  dialogButtonContainer: {
    flexDirection: 'column',
  },
  dialogInnerButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: ' 8px 24px',
  },
  searchContainer: {
    padding: '0px 24px'
  }
};
