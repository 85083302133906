/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { REQUIRED_VALIDATION, FILE_VALIDATION_WITH_TYPE } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate } from 'react-router-dom';
import { IStyles } from '../../constants/theme';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import { CreateRequest } from '../../service/digitalToolkitService';
import RestrictionUsers, { IRestrictionUserSource } from './RestrictionUsers';
import SaveChanges from './SaveChanges';

interface AddDigitalToolkitModel {
  title: string;
  description: string;
  packshot: File;
  published: boolean;
  AudienceUserRole: IVisibilityRestrictionAudienceRole[];
  MerchantUserRole: IVisibilityRestrictionMerchantRole[];
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

const buttonGroupOptions: IOption[] = [
  { title: 'Published', color: 'success', value: true },
  { title: 'Unpublished', color: 'error', value: false }
];

export function AddDigitalToolkit(): JSX.Element {
  const [token] = useTypedSelector(state => [state.userReducer.token]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { control, handleSubmit, formState: { isValid, isDirty }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setValue('published', true, { shouldValidate: true });
  }, [setValue]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  const handleRestrictionChange = ({ selectedAudiences, selectedMerchants }: any) => {
    setValue('AudienceUserRole', selectedAudiences, { shouldDirty: true });
    setValue('MerchantUserRole', selectedMerchants, { shouldDirty: true });
  };

  const submit: SubmitHandler<AddDigitalToolkitModel> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);

      const fileName = await UploadToAzure({
        data: [data.packshot],
        onProgress: onProgress,
        folderName: environment.digitalToolkitFolder
      });

      const fileUrl = fileName[0].Url;

      CreateRequest(
        token,
        {
          Title: data.title,
          Description: data.description,
          FileUrl: fileUrl,
          Published: data.published,
          VisibilityRestrictions: {
            AudienceUserRole: data.AudienceUserRole,
            MerchantUserRole: data.MerchantUserRole
          }
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
        (error) => {
          setErrorMessage(error.response?.data.Error || '');
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses = tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return { ...x, percentage: data.percentage };
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x => {
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  const handleClose = () => {
    if (isDirty) {
      setShowSaveModal(true);
      return;
    }

    navigate(-1);
  };

  const onSaveChangesSubmit = () => {
    setShowSaveModal(false);
    navigate(-1);
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Add Toolkit"
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      close={handleClose}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      {loading
        ? <LinearProgress variant="determinate" color="secondary" value={percentage} sx={{ zIndex: 2 }} />
        : <></>
      }
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        <ModalCardComponent>
          <SectionHeader title="Toolkit details" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  accept=".pdf"
                  data-testid="add-toolkit-packshot"
                  control={control}
                  field={{
                    label: 'Toolkit PDF',
                    name: 'packshot',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: FILE_VALIDATION_WITH_TYPE(50, '.pdf')
                    }
                  }}
                  title="Toolkit PDF"
                  subtitle="Upload the Toolkit PDF."
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-toolkit-title"
                  control={control}
                  field={{
                    label: 'Title',
                    name: 'title',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-toolkit-description"
                  control={control}
                  multiline
                  field={{
                    label: 'Description',
                    name: 'description',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                <AssetStatusButtonGroup
                  subtitle="Published toolkits are visible to customers and unpublished toolkits are hidden."
                  title="Toolkit Status"
                  data-testid="add-toolkit-published"
                  onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                  options={buttonGroupOptions}
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>

        <RestrictionUsers
          service={IRestrictionUserSource.DigitalToolkits}
          onChange={handleRestrictionChange} />

        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>

      <SaveChanges
        visible={showSaveModal}
        onCancel={() => setShowSaveModal(false)}
        onSubmit={onSaveChangesSubmit} />
    </FormPageComponent>
  );
}

const styles: IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
