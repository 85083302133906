/* eslint-disable @typescript-eslint/naming-convention */
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { useEffect, useState } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { gaService } from '../../service/gaService';
import { GetEmailNewsletterDetail } from '../../service/serviceService';
import NewsletterServiceCard from '../../components-organisms/cards/NewsletterServiceCard';
import DetailPageHeader from '../../components-organisms/appbars/DetailPageHeader';
import { Footer } from '../../components-organisms/Footer';

export function EmailNewsletterDetail(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [token, userServices] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.services]);
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState();

  useEffect(() => {
    if (!state?.newsletter) {
      getEmailNewsletter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gaService.pageView('Newsletter Library Detail');
  }, []);

  const getEmailNewsletter = () => {
    if (token && id) {
      setLoading(true);
      GetEmailNewsletterDetail(
        token,
        id,
        (res: any) => {
          if (res) {
            setData(res.Result);
            setLoading(false);
          }
        }
      );
    }
  };

  const onBack = () => {
    const digitalAssetServiceId = userServices.flatMap(f => f.Services).find(m => m.ServiceName === 'Newsletter Library')?.ServiceId;
    const serviceUrl = '/service/';
    if (digitalAssetServiceId) {
      navigate(`${serviceUrl}${digitalAssetServiceId}`);
      return;
    }

    navigate(-1);
  };

  function renderHeader() {
    return (
      <DetailPageHeader
        title="Newsletter Library"
        onBack={onBack} />
    );
  }

  function renderContent() {
    return (
      <Stack sx={{ maxWidth: '672px', margin: '24px auto 48px auto' }}>
        <NewsletterServiceCard
          newsletter={data.Newsletter}
          attachments={data.Attachments}
          contentClass="newsletter-service" />
      </Stack>
    );
  }

  if (loading || !data) {
    return (
      <CircularProgress
        color='primary'
        size={50}
        style={{ zIndex: 98, position: 'absolute', top: '50vh', right: '50vw' }}
      />
    );
  }

  return (
    <Stack flex={1}>
      {renderHeader()}
      {renderContent()}

      <Grid item sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Stack>
  );
}
