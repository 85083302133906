/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Collapse, Stack } from '@mui/material';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { HeaderContainer, clearSelectedAssets } from '../../constants/digitalAssets';
import { HeaderFilter } from '../../components-molecules/header-components/HeaderFilter';
import ServicePageHeaderFirstRow from '../../components-molecules/header-components/ServicePageHeaderFirstRow';
import ServicePageHeaderThirdRow from '../../components-molecules/header-components/ServicePageHeaderThirdRow';
import { CaptionPrimary, CaptionSecondary } from '../../components-atoms/TypographyComponents';

interface IServicePageHeaderProps {
  service: any;
  isAdmin?: boolean;
  hideSearch?: boolean;
  infoModal?: any;
  searchPlaceholder?: string;
  onSearch?: (query: string) => void;
  filterOptions?: any;
  sortOptions?: any;
  selectedSort?: string;
  onChange?: (e: any) => void;
  selectedFilters?: any;
  hasDynamicFilter?: boolean;
  customSearchbar?: any;
  noModal?: boolean;
  onShare?: any;
  shareSubject?: string;
  alwaysShowThirdRow?: boolean
}

export function ServicePageHeader(props: IServicePageHeaderProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [savedQuery] = useTypedSelector((state) => [state.digitalAssetReducer.search]);
  const {
    hasDynamicFilter,
    infoModal,
    service,
    isAdmin,
    searchPlaceholder,
    filterOptions,
    selectedFilters,
    sortOptions,
    selectedSort,
    customSearchbar,
    hideSearch = false,
    onShare,
    shareSubject,
    alwaysShowThirdRow = false
  } = props;
  const [scrollTop, setScrollTop] = useState<any>(0);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const inputRef: any = useRef();
  const [searchParams] = useSearchParams();
  const user = useTypedSelector((state) => state.userReducer.data);
  const isTMorSM = user?.IsTerritoryManager || user?.IsSalesManager;
  const canShare = isAdmin || isTMorSM;

  const shareToken = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('shareToken');
  }, [window.location.search, searchParams]);

  useEffect(() => {
    if (!isAdmin) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

    }
  }, []);

  useEffect(() => {
    clearSelectedAssets(dispatch);
  }, [savedQuery, selectedSort, selectedFilters]);

  function handleScroll() {
    setScrollTop(window.pageYOffset);
  }

  function onToggleFilterMenu() {
    setIsOpen(o => !o);
  }

  function onSearch(search: string) {
    if (props.onSearch) {
      props.onSearch(search);
    }

    if (search.length === 0 && inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  }

  function onChange(e: any) {
    if (props.onChange) {
      props.onChange(e);
    }
  }

  function onChipDelete({ key, value }: any) {
    if (key === 'SharedBrandAsset') {
      onChange({ fetchData: true });
      return;
    }
    onChange({
      target: {
        name: key,
        value: selectedFilters[key].filter((f: any) => f !== value)
      }
    });
  }

  const getChips = useCallback(
    () => {
      const chips = [];

      if (shareToken) {
        chips.push({
          name: shareSubject,
          key: 'SharedBrandAsset',
          value: 'shared-brand-assets',
        });
      }

      const filterChips = Object.keys(filterOptions || []).map(key => {
        return selectedFilters[key].map((f: string) => {
          return {
            ...filterOptions[key].find((o: any) => o.value === f),
            key: key
          };
        });
      }).flat();

      chips.push(...filterChips);
      return chips;
    },
    [filterOptions, selectedFilters, searchParams],
  );

  function firstRow() {
    return (
      <ServicePageHeaderFirstRow
        inputRef={inputRef}
        searchPlaceholder={searchPlaceholder}
        service={service}
        hideSearch={hideSearch}
        infoModal={infoModal}
        filterMenuVisible={isOpen}
        onToggleFilterMenu={hasDynamicFilter ? onToggleFilterMenu : undefined}
        customSearchbar={customSearchbar}
        onSearch={onSearch}
        isAdmin={isAdmin}
      />
    );
  }

  function secondRow() {
    if (filterOptions || hasDynamicFilter) {
      return (
        <Collapse in={isOpen}>
          <HeaderFilter
            hasDynamicFilter={hasDynamicFilter}
            filterOptions={filterOptions}
            selectedFilters={selectedFilters}
            sortOptions={sortOptions}
            selectedSort={selectedSort}
            onChange={onChange} />
        </Collapse>
      );
    }
    return <></>;
  }

  function thirdRow() {
    if (!filterOptions && !alwaysShowThirdRow) { return <></>; }

    return (
      <ServicePageHeaderThirdRow
        onShare={canShare ? onShare : null}
        onChipDelete={onChipDelete}
        chips={getChips()}
        alwaysShow={alwaysShowThirdRow}
      />
    );
  }

  function renderBreadcrumb() {
    if (isAdmin) { return <></>; }
    return (
      <Stack
        display="flex"
        alignItems="flex-start"
        width="100%"
        height="60px"
        pt="12px"
        pb="28px"
        pl={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}>
        <Stack display="flex" flexDirection="row" height="20px">
          <CaptionPrimary sx={{ color: '#012169', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
          <CaptionSecondary sx={{ color: '#D7D4CB', margin: '0 4px' }}>|</CaptionSecondary>
          <CaptionPrimary sx={{ color: '#012169', cursor: 'pointer' }} onClick={() => navigate(`/category/${service.CategoryId}`)}>{service?.CategoryName}</CaptionPrimary>
          <CaptionSecondary sx={{ color: '#D7D4CB', margin: '0 4px' }}>|</CaptionSecondary>
          <CaptionPrimary>{service?.Name}</CaptionPrimary>
        </Stack>
      </Stack>
    );
  }

  if (service?.Name === 'Digital Toolkits') {
    return (
      <>
        <Stack id="container" display="flex" flexDirection="column" width="100%"
          style={{
            width: '100%',
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 99
          }}
        >
          <HeaderContainer scrollTop={scrollTop}>
            {firstRow()}
          </HeaderContainer>
        </Stack>
        {renderBreadcrumb()}
      </>
    );
  }

  return (
    <>
      <Stack id="container" display="flex" flexDirection="column"
        sx={{
          width: '100%',
          position: 'sticky',
          top: isAdmin ? '60px' : 0,
          left: 0,
          zIndex: 99,
          mb: 1
        }}
      >
        <HeaderContainer scrollTop={scrollTop}>
          {firstRow()}
          {secondRow()}
          {thirdRow()}
        </HeaderContainer>
      </Stack>
      {renderBreadcrumb()}
    </>
  );
}
