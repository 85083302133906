const INITIAL: StoreState = {
  currentStore: {
    id: '',
    name: '',
    customerCode: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postCode: '',
    county: '',
    email: '',
    phone: '',
  },
  employeeList: [],
  managerList: [],
  territoryManager: undefined,
  salesManagers: [],
  merchantManagerList: [],
};

export const storeReducer = (state: StoreState = INITIAL, action: StoreAction): StoreState => {
  switch (action.type) {
    case 'SET_CURRENT_STORE':
      return { ...state, currentStore: action.payload.store, employeeList: action.payload.employeeList, managerList: action.payload.managerList, merchantManagerList: action.payload.merchantManagerList, territoryManager: action.payload.territoryManager, salesManagers: action.payload.salesManagers };
    default:
      return state;
  }
};
