import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ShareContentPage } from './ShareContentPage';
import { ShareDigitalToolkitRequest } from '../../service/serviceService';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';

export default function DigitalToolkitShare(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = useTypedSelector(state => state.userReducer.token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (token && id) {
      const merchantRecipientIds = data?.MerchantRecipientIds?.map((r: any) => r?.Recipients?.map((re: any) => re.Id)).flat();
      const portalRecipientIds = data?.PortalManagerRecipientIds?.map((r: any) => r.Id);

      setLoading(true);
      ShareDigitalToolkitRequest(
        token,
        {
          Body: data.body,
          Subject: data.subject,
          DigitalToolkitId: id,
          MerchantRecipientIds: merchantRecipientIds,
          PortalManagerRecipientIds: portalRecipientIds
        },
        () => {
          navigate(-1);
        },
        (error) => {
          setError(error.response?.data.Error || '');
          setLoading(false);
        }
      );
    }
  };

  return (
    <>
      <ShareContentPage
        title='Share Digital Toolkit'
        loading={loading}
        error={error}
        onSubmit={(data: ISharePageContent) => onSubmit(data)} />
    </>
  );
}
