/* eslint-disable @typescript-eslint/naming-convention */
const testEnv = {
  apiUrl: 'https://mc-api.hncdev.co.uk',
  azureConnectionString: 'BlobEndpoint=https://hncdev.blob.core.windows.net/;QueueEndpoint=https://hncdev.queue.core.windows.net/;FileEndpoint=https://hncdev.file.core.windows.net/;TableEndpoint=https://hncdev.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=b&srt=sco&sp=rwdlacx&se=2025-09-01T18:07:11Z&st=2024-08-22T10:07:11Z&spr=https&sig=ow7nxtGSXu4BNwr6lHuXhffJN4pW8Dnpe0wfXzR%2Fk4s%3D',
  containerName: 'merchantconnect-dev',
  docRepoContainerName: 'merchantconnect-doc-repo-dev',
  assetFolder: 'brand_assets_images',
  merchantFolder: 'logos',
  articleFolder: 'articles',
  productFolder: 'npd_images',
  digitalToolkitFolder: 'digitaltoolkit',
  industryEventsFolder: 'industryevents',
  insightFolder: 'industry_insight',
  newsletterFolder: 'newsletter',
  industryCloudUrl: 'https://dev-ic.hnccloud.com/',
  gaToken: 'G-K5FYQ2TC50',
  gaAdminToken: 'G-79SN2BTMHP',
  heritageToolkitId: '193e890b-0755-44cb-987d-b1cf0cd6d879'
};

type EnvironmentVariables = typeof testEnv;

const liveEnv = (window as any).__env__ as EnvironmentVariables;

export const environment = liveEnv || testEnv;
