/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState, Fragment } from 'react';
import { Grid, IconButton, CircularProgress, Box } from '@mui/material';
import Add from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SearchIcon from '@mui/icons-material/Search';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { MiniCard, MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { ListDivider } from '../../components-molecules/Divider';
import { SortButton } from '../../components-molecules/SortButton';

import { ButtonContainedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { BodyOnePrimary, BodyTwoSecondary, CaptionSecondary } from '../../components-atoms/TypographyComponents';

import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { ThreadMerchantEmployee, GetThreadTerritoryManagers, ThreadTerritoryManagersResponse, ThreadTerritoryManagers } from '../../service/serviceService';
import { IStyles, LIGHT_THEME } from '../../constants/theme';
import { FilterMenu, IFilterOptions } from '../../components-molecules/FilterMenu';
import { GetSalesManagerRoles } from '../../service/adminService';
import { SearchbarWithDeboubce } from '../../components-molecules/SearchBarWithDebounce';

const SORT_TYPES = [
  {
    title: 'A \u2014 Z',
    value: 'asc'
  },
  {
    title: 'Z \u2014 A',
    value: 'desc'
  }
];

const styles: IStyles = {
  sortFilterButton: {
    margin: '0 8px 0 0',
    height: '48px',
    width: '48px',
    '&:hover': {
      backgroundColor: LIGHT_THEME.palette.action.selected
    }
  },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
};

export interface IPortalManagerRecipientProps {
  title?: string;
  description?: string;
  onChange?: (data: any) => void;
}

export default function PortalManagerRecipient(props: IPortalManagerRecipientProps): JSX.Element {
  const { title, description, onChange } = props;
  const token = useTypedSelector(state => state.userReducer.token);
  const [dialog, setDialog] = useState(false);
  const [territoryManagers, setTerritoryManagers] = useState<ThreadTerritoryManagersResponse[]>([]);

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sort, setSort] = useState('asc');
  const [recipientLoading, setRecipientLoading] = useState(false);
  const [tempTerritoryManager, setTempTerritoryManager] = useState<ThreadTerritoryManagers[]>([]);
  const [selectedTerritoryManagers, setSelectedTerritoryManagers] = useState<ThreadTerritoryManagers[]>([]);
  const recipientType = 'territoryManager';
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({ Roles: [] });
  const [filter, setFilter] = useState<ISelectedOptions>({ Roles: [] });

  useEffect(() => {
    if (token) {
      GetSalesManagerRoles(token,
        (res) => {
          setFilterOptions({
            Roles: [...res.Result.map(m => ({ title: m.Name, value: m.Id })), { title: 'Territory Manager', value: '00000000-0000-0000-0000-000000000000' }]
          });
        },
        (err) => { console.log(err); });
    }
  }, [token]);

  const getTerritoryManagerRecipientList = () => {
    if (token) {
      setRecipientLoading(true);
      GetThreadTerritoryManagers(
        token, {
          Query: searchQuery,
          OrderBy: sort,
          RoleIds: filter.Roles
        },
        response => {
          setTerritoryManagers(response.Result);
          setRecipientLoading(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };
  useEffect(() => {
    if (token) {
      getTerritoryManagerRecipientList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, searchQuery, sort, filter]);

  const searchOnChange = (text: string) => {
    setSearchQuery(text);
  };

  const removeTerritoryManagerFromList = (id: GUID) => {
    const list = selectedTerritoryManagers.filter(manager => manager.Id !== id);
    setSelectedTerritoryManagers(list);
    onChange?.(list);
  };

  const GetTerritoryManagerList = () => {
    if (!selectedTerritoryManagers) {
      return <></>;
    }

    return (
      <Fragment>
        {selectedTerritoryManagers.map((manager) =>
          <Fragment key={manager.Id}>
            <Grid container alignItems="center" sx={{ margin: '16px 0' }}>
              <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
                <BodyTwoSecondary style={{ marginBottom: '4px' }}>Territory Manager</BodyTwoSecondary>
                <BodyOnePrimary>{manager.FirstName + ' ' + manager.LastName}</BodyOnePrimary>
                <CaptionSecondary>{manager.Email}</CaptionSecondary>
              </Grid>
              {manager.Merchants && manager.Merchants.length > 0 && (
                <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
                  <BodyTwoSecondary style={{ marginBottom: '4px' }}>Merchants</BodyTwoSecondary>
                  <BodyOnePrimary
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2
                    }}
                  >
                    {manager.Merchants.map(merchant => merchant).join(', ')}
                  </BodyOnePrimary>
                </Grid>
              )}
              <Grid item>
                <RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => removeTerritoryManagerFromList(manager.Id)} />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  };

  const NoRecipientSelected = () => {
    return (
      <InfoComponent
        title={'Email Recipients'}
        description={description || 'You haven’t set any recipients for this thread yet. You can select single or multiple users of the same organisation.'}
      />
    );
  };

  const GetRecipientList = () => {
    if (selectedTerritoryManagers.length > 0) {
      return <GetTerritoryManagerList />;
    } else {
      return <NoRecipientSelected />;
    }
  };

  const closeClicked = () => {
    setDialog(false);
  };

  const addRecipientsClicked = () => {
    setDialog(true);
    setTempTerritoryManager(selectedTerritoryManagers);
  };

  const handleSelectionAllTerritoryManagers = (selection: boolean) => {
    if (selection) {
      const managers: ThreadTerritoryManagers[] = [];
      territoryManagers.forEach(group => group.TerritoryManagers.forEach(x => managers.push(x)));
      setTempTerritoryManager(managers);
    } else {
      setTempTerritoryManager([]);
    }
  };

  const customTerritoryManagerBottomButtons = () => {
    return <Box sx={{ height: '32px', margin: '16px', alignSelf: 'end', display: 'flex', justifyContent: 'space-between', width: '99%', paddingLeft: '16px', paddingRight: '8px' }}>
      <ButtonTextPrimary onClick={closeClicked} data-testid='close-button'>Cancel</ButtonTextPrimary>
      <ButtonTextPrimary onClick={territoryManagerSubmitPressed} disabled={tempTerritoryManager.length === 0} data-testid='close-button'>Submit</ButtonTextPrimary>
    </Box>;
  };

  const territoryManagerSubmitPressed = () => {
    const temp = tempTerritoryManager.filter(x => selectedTerritoryManagers.map(y => {
      if (x.Id === y.Id) {
        return true;
      }
    }));
    setSelectedTerritoryManagers(temp);
    setTempTerritoryManager([]);
    setShowSearchInput(false);
    setSort('asc');
    setSearchQuery('');
    setDialog(false);
    onChange?.(temp);
  };

  const saveManager = (manager: ThreadTerritoryManagers) => {
    let temp = JSON.parse(JSON.stringify(tempTerritoryManager));
    if (getSelectedTerritoryManager(manager)) {
      temp = temp.filter((x: ThreadMerchantEmployee) => x.Id !== manager.Id);
    } else {
      temp.push(manager);
    }
    setTempTerritoryManager(temp);
  };

  const getSelectedTerritoryManager = (manager: ThreadTerritoryManagers) => {
    let isSelected = false;
    tempTerritoryManager.forEach(x => {
      if (x.Id === manager.Id) {
        isSelected = true;
      }
    });
    return isSelected;
  };
  const getTerritoryManagerList = () => {
    if (recipientLoading) {
      return <Grid container item justifyContent="center" alignItems="center">
        <CircularProgress
          color="primary"
          size={50}
          style={{ zIndex: 999, margin: '56px 0' }}
        />
      </Grid>;
    }

    return territoryManagers.map(group => (
      <ListDivider
        key={group.Group}
        division={{ name: group.Group, length: group.TerritoryManagers.length }}
        caption={{ singular: 'Manager', plural: 'Managers' }}
        halfMargin
      >
        {
          group.TerritoryManagers.map(manager => <MiniCard
            key={manager.Id}
            onClick={() => saveManager(manager)}
            title={`${manager.FirstName} ${manager.LastName}`}
            subtitle={manager.Merchants.map(merchant => merchant).join(', ')}
            secondSubtitle={manager.Email}
            thirdSubtitle={manager.RoleName}
            selected={getSelectedTerritoryManager(manager)}
          />)
        }
      </ListDivider>
    ));
  };

  return (
    <ModalCardComponent>
      <SectionHeader
        title={title || 'Recipients'}
        button={
          <ButtonContainedPrimary
            data-testid="add-recipients"
            onClick={addRecipientsClicked}
            startIcon={<Add />}
          >
            Add Recipients
          </ButtonContainedPrimary>
        }
      />
      <Grid item container sx={{ marginTop: 1, flexGrow: 1 }}>
        <GetRecipientList />
      </Grid>

      <MiniDialog
        title="Select Managers"
        open={dialog && recipientType === 'territoryManager'}
        close={closeClicked}
        customBottomButtons={customTerritoryManagerBottomButtons}
        titleMargin
        childrenSx={{ width: '100%' }}
      >
        <>
          <div style={{
            flexDirection: 'column',
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'flex-end',
              padding: '8px 24px',
            }}>
              <IconButton aria-label="search" sx={styles.sortFilterButton} onClick={() => setShowSearchInput(!showSearchInput)}>
                <SearchIcon color={'primary'} />
              </IconButton>
              <SortButton
                menuItems={
                  SORT_TYPES.map((sortItem: { title: string; value: string; }) => ({
                    title: sortItem.title,
                    value: sortItem.value,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    handleSelect: () => { setSort(sortItem.value); },
                  }))}
                selectedSortType={sort}
              />
              <FilterMenu
                filterOptions={filterOptions}
                onSubmit={(selected) => setFilter(selected)}
                activeButtons />

              <ButtonTextPrimary
                data-testid="selectall-button"
                onClick={() => handleSelectionAllTerritoryManagers(true)}
                sx={{ margin: '0 8px 0 0', padding: '8px 11px !important' }}>
                Select All
              </ButtonTextPrimary>

              <ButtonTextPrimary
                data-testid="deselectall-button"
                onClick={() => handleSelectionAllTerritoryManagers(false)}
                disabled={tempTerritoryManager.length === 0}
                sx={{ margin: '0 8px 0 0', padding: '8px 11px !important' }}>
                Deselect All
              </ButtonTextPrimary>
            </div>
            {showSearchInput && (
              <div style={{ padding: ' 0px 24px' }}>
                <SearchbarWithDeboubce
                  isOpen
                  query={searchQuery}
                  placeholder="Search Managers"
                  onCancel={() => { setShowSearchInput(false); setSearchQuery(''); }}
                  onChange={searchOnChange}
                />
              </div>
            )}
          </div>
          <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
            {getTerritoryManagerList()}
          </div>
        </>
      </MiniDialog>
    </ModalCardComponent>
  );
}
