import { DEFAULT_BRAND_ASSET_SORT } from '../../constants/digitalAssets';

export const DIGITAL_ASSET_REDUX_FILTER_INITIAL: any = { 
  Brand: [], 
  Product: [],
  Colour: [], 
  'Asset Type': [], 
  'File Type': [], 
  Orientation: [], 
  Size: []
};  

const INITIAL: DigitalAssetState = {
  currentData: {
    DigitalAsset: {
      DigitalAssetId: '' as GUID,
      DigitalAssetName: '',
      Barcode: '',
      Fini: '',
      Published: false,
      PublicationDate: null,
      Created: null,
      DigitalAssetOrientationId: '' as GUID,
      DigitalAssetTypeId: '' as GUID,
      BrandId: '' as GUID,
      Brand: '',
      ProductId: '' as GUID,
      Product: '',
      AkzoColourId: '',
      Colour: '',
      Orientation: '',
      FocusedPriority: null,
      ShouldSendEmailNotification: false,
      VisibilityRestrictions: {
        AudienceUserRole: [],
        MerchantUserRole: []
      }
    },
    DigitalAssetFile: [],
    DigitalAssetTag: [],
  },
  assetList:[],
  focusedContents: [],
  assetListToShow: [],
  selectedAssets: [],
  noFocusedContents: 0,
  filter: DIGITAL_ASSET_REDUX_FILTER_INITIAL,
  search:'',
  sortType: DEFAULT_BRAND_ASSET_SORT,
  loading: false,
  offset: 0,
  top: 0,
  refresh: false
};

export const digitalAssetReducer = (state: DigitalAssetState = INITIAL, action: DigitalAssetAction): DigitalAssetState => {
  switch (action.type) {
    case 'SET_DIGITAL_ASSET_LOADING':
      return { ...state, loading: action.payload.loading };
    case 'SET_CURRENT_DIGITAL_ASSET':
      return { ...state, currentData: action.payload.currentData };
    case 'SET_DIGITAL_ASSET_LIST_TO_SHOW':
      return { ...state, assetListToShow: action.payload.assetListToShow };
    case 'SET_DIGITAL_ASSET_LIST':
      return { ...state, assetList: action.payload.assetList };
    case 'SET_DIGITAL_ASSET_FILTER':
      return { ...state, filter: action.payload.filter };
    case 'SET_DIGITAL_ASSET_SEARCH':
      return { ...state, search: action.payload.search };
    case 'SET_DIGITAL_ASSET_SORT':
      return { ...state, sortType: action.payload.sortType };
    case 'SET_DIGITAL_ASSET_SELECT':
      return { ...state, selectedAssets: action.payload.selectedAssets };
    case 'SET_DIGITAL_ASSET_OFFSET':
      return { ...state, offset: action.payload.offset };
    case 'SET_DIGITAL_ASSET_TOP':
      return { ...state, top: action.payload.top };
    case 'RESET_DIGITAL_ASSET_LIST':
      return { ...state, assetListToShow: [] };
    case 'RESET_DIGITAL_ASSET_FILTER':
      return { ...state, filter: DIGITAL_ASSET_REDUX_FILTER_INITIAL, assetListToShow: [] };
    case 'SET_DIGITAL_ASSET_SEARCH_AND_SORT':
      return { ...state, assetListToShow: [], filter: DIGITAL_ASSET_REDUX_FILTER_INITIAL, search: action.payload.search, sortType: action.payload.sortType };
    case 'SET_DIGITAL_ASSET_FOCUSED_CONTENTS':      
      return { ...state, focusedContents: action.payload.focusedContents };
    case 'SET_DIGITAL_ASSET_NO_FOCUSED_CONTENTS':      
      return { ...state, noFocusedContents: action.payload.noFocusedContents };
    case 'DIGITAL_ASSET_REFRESH':
      return { ...state, refresh: !state.refresh };
    default:
      return state;
  }
};
