/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Container, IconButton, Typography } from '@mui/material';
import { ButtonContainedPrimary, ButtonTextPrimary } from '../components-atoms/ButtonComponents';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import BusinessIcon from '@mui/icons-material/Business';
import { SortButton } from '../components-molecules/SortButton';
import SearchIcon from '@mui/icons-material/Search';
import { LIGHT_THEME } from '../constants/theme';
import { ListDivider } from '../components-molecules/Divider';
import { AssignStoreListItem, GetSelectedMerchants, GetSelectedMerchantsResponseModel, ListAssignMerchantsModel, ListAssignMerchantsRequest, ListAssignMerchantsResponseModel, ListAssignMerchantsWithSMIdRequest, ListAssignStoresRequest, SelectedMerchantsResultModel, SubmitAssignMerchantsRequest, SubmitAssignMerchantsRequestModel } from '../service/merchantsService';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { MerchantAssignCardItem } from '../components-molecules/MerchantAssignCardItem';
import { AZ_SORT, SortType } from '../utils/sortTypes';
import { AssignCardItem } from '../components-molecules/StoreAssignCardItem';
import { BodyTwoPrimary, CaptionSecondary } from '../components-atoms/TypographyComponents';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

const SIZE = 50;

export interface CustomSubmitModel {
  StoreIds?: { [key: string]: GUID[]; },
  MerchantIds?: string[];
}

export interface IAssignModalProps {
  onSubmit?: () => void;
  onCustomSubmit?: (requestModel: CustomSubmitModel, displayNames: { id: string, type: string, name: string; }[]) => void;
  customTitle?: string;
  initialSelected?: { Merchants?: GUID[]; Stores?: { [key: string]: GUID[]; }; };
  getListWithSMId?: GUID;
}

export function MerchantAssignModal({ onSubmit, onCustomSubmit, customTitle, initialSelected, getListWithSMId }: IAssignModalProps): JSX.Element {
  const merchantListDiv = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  // Merchant
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [sort, setSort] = useState<SortType>(AZ_SORT[0]);
  const [query, setQuery] = useState<string>('');

  // Store
  const [storeSearchOpen, setStoreSearchOpen] = useState<boolean>(false);
  const [storeSort, setStoreSort] = useState<SortType>(AZ_SORT[0]);
  const [storeQuery, setStoreQuery] = useState<string>('');

  const [merchants, setMerchants] = useState<ListAssignMerchantsModel[]>([]);
  const [stores, setStores] = useState<AssignStoreListItem[]>([]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState(0);
  const [token] = useTypedSelector((state) => [state.userReducer.token]);
  const [merchantDetail, setMerchantDetail] = useState<Merchant>();

  const [selectedMerchants, setSelectedMerchants] = useState<GUID[]>([]);
  const [selectedStores, setSelectedStores] = useState<{ [key: string]: GUID[]; }>();

  const [scrollTop, setScrollTop] = useState<number>(0);

  const getSelectedMerchantsList = useCallback(() => {
    if (!token || !open) { return; }

    if (initialSelected) {
      setSelectedMerchants(initialSelected.Merchants || []);
      setSelectedStores(initialSelected.Stores);
    }
    else {
      GetSelectedMerchants(
        token,
        (response: ApiResponse<GetSelectedMerchantsResponseModel>) => {
          if (response) {
            const merchantIds: GUID[] = [];
            let storeIds: { [key: string]: GUID[]; } = {};

            if (Array.isArray(response.Result)) {
              response.Result.forEach((m: SelectedMerchantsResultModel) => {
                if (m.IsSelected) {
                  merchantIds.push(m.MerchantId);
                }

                if (m.Stores.length > 0) {
                  storeIds = {
                    ...storeIds,
                    [m.MerchantId]: m.Stores
                  };
                }
              });
            }

            setSelectedMerchants(merchantIds);
            setSelectedStores(storeIds);
          }
        },
        (error) => {
          if (error.response) {
            console.error(error.response.data.Error);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, token]);

  const handleMerchantResponse = (response: ApiResponse<ListAssignMerchantsResponseModel>, p: number) => {
    if (!response) { return; }

    // No previous values,
    // set the response.
    if (p === 0) {
      setMerchants(response.Result.Merchants);
      setCount(response.Result.Count);
    } else {
      setMerchants((prev) => {
        let temp = [...prev];

        response.Result.Merchants.forEach(m => {
          const tempMatch = temp.find(f => f.Group === m.Group);
          if (tempMatch) {
            temp[temp.indexOf(tempMatch)].Merchants = [
              ...temp[temp.indexOf(tempMatch)].Merchants,
              ...m.Merchants
            ];
          } else {
            temp = [...temp, m];
          }
        });

        return temp;
      });
    }
    setLoading(false);
  };

  const getMerchants = useCallback((p = 0): void => {
    if (!token) { return; }

    if (getListWithSMId) {
      ListAssignMerchantsWithSMIdRequest(
        token,
        getListWithSMId,
        {
          Query: query,
          Size: SIZE,
          Page: p,
          OrderBy: sort.value
        },
        (response) => {
          handleMerchantResponse(response, p);
        },
        (error) => {
          if (error.response) {
            console.error(error.response.data.Error);
            setLoading(false);
          }
        }
      );
    }
    else {
      ListAssignMerchantsRequest(
        token,
        {
          Query: query,
          Size: SIZE,
          Page: p,
          OrderBy: sort.value
        },
        (response) => {
          handleMerchantResponse(response, p);
        },
        (error) => {
          if (error.response) {
            console.error(error.response.data.Error);
            setLoading(false);
          }
        }
      );
    }
  }, [token, query, sort.value]);

  const getStores = useCallback((): void => {
    if (!token) { return; }

    ListAssignStoresRequest(
      token,
      {
        MerchantId: merchantDetail?.Id,
        Query: storeQuery,
        OrderBy: storeSort.value
      },
      (response: ApiResponse<AssignStoreListItem[]>) => {
        if (response) {
          setStores(response.Result);
          setLoading(false);
        }
      },
      (error) => {
        if (error.response) {
          console.error(error.response.data.Error);
          setLoading(false);
        }
      }
    );
  }, [merchantDetail?.Id, storeQuery, storeSort.value, token]);

  useEffect(() => {
    if (merchantDetail?.Id) {
      setLoading(true);
    }
  }, [merchantDetail?.Id]);

  useEffect(() => {
    if (token && open) {
      getMerchants();
    }
  }, [getMerchants, open, query, sort, token]);

  useEffect(() => {
    if (token && merchantDetail?.Id) {
      getStores();
    }
  }, [getStores, merchantDetail?.Id, storeQuery, storeSort, token]);

  useEffect(() => {
    getSelectedMerchantsList();
  }, [getSelectedMerchantsList]);

  const submitDialog = (): void => {
    if (!token) { return; }
    setLoading(true);

    if (onCustomSubmit) {
      const body: CustomSubmitModel = {};

      if (selectedMerchants.length > 0) {
        body.MerchantIds = selectedMerchants;
      }

      if (selectedStores && Object.keys(selectedStores).length > 0) {
        body.StoreIds = selectedStores;
      }

      const displayNames: { id: string; type: string; name: string; }[] = [];

      selectedMerchants.map(mId => {
        const name = merchants.find(f => f.Merchants.find(f => f.Id === mId))?.Merchants.find(f => f.Id === mId)?.Name;
        displayNames.push({ id: mId, type: 'Merchant', name: name || '' });
      });

      for (const mid in selectedStores) {
        const merchantName = merchants.find(m => m.Merchants.find(f => f.Id === mid))?.Merchants.find(f => f.Id === mid)?.Name;
        selectedStores[mid].forEach(storeid => {
          //const name = stores.find(s => s.Stores.find(f => f.Id === storeid))?.Stores.find(f => f.Id === storeid)?.Name;
          displayNames.push({ id: storeid, type: 'Store', name: `${merchantName} — ${storeid}` });
        });
      }

      onCustomSubmit(body, displayNames);
      setLoading(false);
      setOpen(false);
    }

    if (onSubmit) {
      let storeIds: GUID[] = [];
      if (selectedStores) {
        for (const [, value] of Object.entries(selectedStores)) {
          storeIds = [...storeIds, ...value as Array<GUID>];
        }
      }

      const body: SubmitAssignMerchantsRequestModel = {};
      if (selectedMerchants.length > 0) {
        body.MerchantIds = selectedMerchants;
      }
      if (storeIds.length > 0) {
        body.StoreIds = storeIds;
      }

      SubmitAssignMerchantsRequest(
        token,
        body,
        (response) => {
          if (response) {
            setLoading(false);
            setOpen(false);
            onSubmit();
          }
        },
        (error) => {
          if (error.response) {
            console.error(error.response.data.Error);
            setLoading(false);
          }
        }
      );
    }
  };

  const onMerchantCheckboxChange = (checked: boolean, merchant: Merchant) => {
    if (checked) {
      // Set selected merchants
      setSelectedMerchants(m => ([
        ...m,
        merchant.Id
      ]));

      // Clear selectedStores if exist
      setSelectedStores(s => {
        const tempStores = { ...s };
        delete tempStores[merchant.Id];
        return tempStores;
      });
    } else {
      // Remove it from selected arr.
      setSelectedMerchants((m: GUID[]) => ([
        ...m.filter(f => f !== merchant.Id),
      ]));
    }
  };

  const onStoreCheckboxChange = (checked: boolean, store: Store) => {
    // Check
    if (checked) {
      // If exist
      if (selectedStores?.[merchantDetail?.Id]) {
        setSelectedStores((m: any) => ({
          ...m,
          [merchantDetail?.Id]: [
            ...m[merchantDetail?.Id],
            store.Id
          ]
        }));
      } else {
        setSelectedStores(m => ({
          ...m,
          [merchantDetail?.Id]: [
            store.Id
          ]
        }));
      }
    }

    // Uncheck
    else {
      // We have partial selection,
      // apply filter logic
      if (selectedStores?.[merchantDetail?.Id]) {
        setSelectedStores(m => ({
          ...m,
          [merchantDetail?.Id]: m?.[merchantDetail?.Id].filter(s => s !== store.Id)
        }));
      } else {
        // TODO: Move this into a func.
        const storeIds: GUID[] = [];

        stores.forEach(r =>
          r.Stores.forEach(s => {
            if (s.Id != store.Id) {
              storeIds.push(s.Id);
            }
          }));

        setSelectedStores(m => ({
          ...m,
          [merchantDetail?.Id]: storeIds
        }));

        setSelectedMerchants((m: GUID[]) =>
          m.filter(f => f !== merchantDetail?.Id)
        );
      }
    }
  };

  const selectAllStores = () => {
    const allStores: GUID[] = [];
    stores.forEach(a => a.Stores.forEach(s => allStores.push(s.Id)));
    setSelectedStores(m => ({
      ...m,
      [merchantDetail?.Id]: allStores
    }));
  };

  const deselectAllStores = () => {
    setSelectedStores((s: any) => (s[merchantDetail?.Id]?.filter((m: any) => m === merchantDetail?.Id)));
  };

  const loadMore = () => {
    setPage((p: number) => p + 1);
    getMerchants(page + 1);
  };

  const getDataLength = useCallback(() => {
    return merchants.reduce(
      (accumulator: number, c: ListAssignMerchantsModel) => accumulator + c.Merchants.length,
      0
    );
  }, [merchants]);

  const onMerchantSelect = (m: Merchant) => {
    setMerchantDetail(m);

    if (merchantListDiv.current) {
      setScrollTop(merchantListDiv.current.scrollTop);
    }
  };

  const onBack = () => {
    setMerchantDetail(undefined);

    setTimeout(() => {
      if (merchantListDiv.current) {
        merchantListDiv.current.scrollTo(0, scrollTop);
      }
    }, 10);
  };

  function headerRight() {
    return (
      <Box display="flex" justifyContent="flex-end" mr={2}>
        <IconButton
          aria-label="search"
          sx={{ margin: '0 8px 0 0', height: '48px', width: '48px', '&:hover': { backgroundColor: LIGHT_THEME.palette.action.selected } }}
          onClick={() => setSearchOpen(true)}>
          <SearchIcon color={'primary'} />
        </IconButton>

        <SortButton
          menuItems={AZ_SORT.map((sortItem: { title: string; value: string; }) => ({
            title: sortItem.title,
            value: sortItem.value,
            handleSelect: () => {
              setLoading(true);
              setPage(0);
              setSort(sortItem);
            },
          }))}
          selectedSortType={sort}
        />
      </Box>
    );
  }

  function getStoreCount() {
    let count = 0;
    stores.forEach(r =>
      r.Stores.forEach(() => {
        count++;
      }));
    return count;
  }

  function storeHeader() {
    const selectDisabled = stores.length === 0 || selectedMerchants.findIndex(m => m === merchantDetail?.Id) !== -1 || selectedStores?.[merchantDetail?.Id]?.length === getStoreCount();
    const deselectDisabled = selectedStores?.[merchantDetail?.Id] === undefined || selectedStores[merchantDetail?.Id]?.length === 0;
    return (
      <Box display="flex" alignItems="center" textAlign="center" justifyContent="flex-end" mr={1}>
        <IconButton
          aria-label="search"
          sx={{ margin: '0 8px 0 0', height: '48px', width: '48px', '&:hover': { backgroundColor: LIGHT_THEME.palette.action.selected } }}
          onClick={() => setStoreSearchOpen(true)}>
          <SearchIcon color={'primary'} />
        </IconButton>

        <SortButton
          menuItems={AZ_SORT.map((sortItem: { title: string; value: string; }) => ({
            title: sortItem.title,
            value: sortItem.value,
            handleSelect: () => {
              setLoading(true);
              setStoreSort(sortItem);
            },
          }))}
          selectedSortType={sort}
        />

        <ButtonTextPrimary
          data-testid="selectall-button"
          onClick={selectAllStores}
          disabled={selectDisabled}
          sx={{ margin: '0 8px 0 0', padding: '8px 11px !important' }}>
          Select All
        </ButtonTextPrimary>

        <ButtonTextPrimary
          data-testid="deselectall-button"
          onClick={deselectAllStores}
          disabled={deselectDisabled}
          sx={{ margin: '0 8px 0 0', padding: '8px 11px !important' }}>
          Deselect All
        </ButtonTextPrimary>
      </Box>
    );
  }

  function renderLoading() {
    return (
      <Box display="flex" justifyContent="center" pt={18} height="423px">
        <CircularProgress
          color='primary'
          size={50}
        />
      </Box>
    );
  }

  function renderNoResult() {
    return (
      <BodyTwoPrimary sx={{ padding: '16px 24px 0', mb: 4 }}>0 results found.</BodyTwoPrimary>
    );
  }

  function renderMerchantList() {
    return (
      <div
        id="scrollableDiv"
        ref={merchantListDiv}
        style={{ overflow: 'auto', height: '360px' }}>
        <InfiniteScroll
          dataLength={getDataLength()}
          next={loadMore}
          hasMore={getDataLength() < count}
          scrollableTarget="scrollableDiv"
          style={{ overflow: 'hidden' }}
          loader={
            <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
              <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
            </Container>
          }
        >
          <Box mb={2} ml={2} mr={2}>
            <SearchbarWithDeboubce
              isOpen={searchOpen}
              query={query}
              placeholder="Search Merchants"
              onChange={(text: string) => setQuery(text)}
              onCancel={() => { setSearchOpen(false); setQuery(''); setPage(0); }}
            />
          </Box>

          {merchants.length === 0
            ? renderNoResult()
            : merchants.map((section, id: number) => {
              return (
                <ListDivider
                  noMargin
                  key={`${section.Group}-${id}`}
                  division={{ name: section.Group, length: section.Merchants.length }}
                  caption={{ singular: 'Merchant', plural: 'Merchants' }}
                  sx={{ pt: 2 }}>
                  {section.Merchants.map((item, id: number) =>
                    <MerchantAssignCardItem
                      key={`${item.Id}-${id}`}
                      merchant={item}
                      disabled={item.SelectedByTerritoryManager}
                      selected={Boolean(selectedMerchants.find(s => s === item.Id)) || item.SelectedByTerritoryManager}
                      storeSelected={selectedStores?.[item?.Id]?.length}
                      onChange={onMerchantCheckboxChange}
                      onSelect={onMerchantSelect}
                      totalStore={item.TotalStore}
                    />
                  )}
                </ListDivider>
              );
            })}
        </InfiniteScroll>
      </div>
    );
  }

  function renderStoreList() {
    return (
      <Box display="flex" flexDirection="column" height="423px">
        <Box mb={2} ml={1} mr={1}>
          <SearchbarWithDeboubce
            isOpen={storeSearchOpen}
            query={storeQuery}
            placeholder="Search Stores"
            onChange={text => setStoreQuery(text)}
            onCancel={() => { setStoreSearchOpen(false); setStoreQuery(''); }}
          />
        </Box>

        {stores.length === 0
          ? renderNoResult()
          : stores.map(section => {
            return (
              <ListDivider
                noMargin
                key={section.Group}
                division={{ name: section.Group, length: section.Stores.length }}
                caption={{ singular: 'Store', plural: 'Stores' }}
                sx={{ mb: 1 }}
              >
                {section.Stores.map(item =>
                  <AssignCardItem
                    key={item.Id}
                    item={item}
                    selected={Boolean(selectedMerchants.find(s => s === merchantDetail?.Id)) || Boolean(selectedStores?.[merchantDetail?.Id]?.find(s => s === item.Id))}
                    onChange={onStoreCheckboxChange}
                    additionalInfo={() =>
                      <>
                        <CaptionSecondary>
                          {item.PostCode}
                        </CaptionSecondary>
                        <CaptionSecondary>
                          Territory Manager
                        </CaptionSecondary>
                        <CaptionSecondary>
                          {item.FirstName ? `${item.FirstName} ${item.LastName}` : '/'}
                        </CaptionSecondary>
                      </>
                    }
                  />
                )}
              </ListDivider>
            );
          })}
      </Box>
    );
  }

  function renderHelp() {
    return (
      <Box pl={3} pr={3}>
        <Typography color="text.secondary">
          You can add merchants from the merchants list. This will include all existing merchant’s stores and all stores which might be added in the future.
        </Typography>

        <Typography mt={1} color="text.secondary">
          If you wish to add only existing merchant’s stores go to merchant details screen and select all existing stores instead. When new stores will be added they won’t be included to your selection.
        </Typography>
      </Box>
    );
  }

  const title = merchantDetail ? merchantDetail.Name : helpOpen ? 'Adding Merchants' : 'Merchants';
  return (
    <>
      <ButtonContainedPrimary
        data-testid={'Assign From List'}
        startIcon={<BusinessIcon />}
        onClick={() => { setPage(0); setOpen(true); }}
        sx={{ margin: '0 0 0 8xx', padding: '8px 11px !important', width: 'max-content' }}>
        {customTitle ? customTitle : 'Assign From List'}
      </ButtonContainedPrimary>

      <MiniDialog
        noMaxHeight
        title={title}
        open={open}
        submit={helpOpen ? undefined : () => submitDialog()}
        header={helpOpen ? undefined : merchantDetail ? storeHeader : headerRight}
        noCancel={helpOpen}
        onBack={onBack}
        onHelpClick={helpOpen || merchantDetail ? undefined : () => setHelpOpen(true)}
        backEnabled={Boolean(merchantDetail)}
        customBottomButtons={merchantDetail ? () => <></> : undefined}
        close={() => {
          if (helpOpen) {
            setHelpOpen(false);
            return;
          }

          if (merchantDetail?.Id) {
            setMerchantDetail(undefined);
          }

          setOpen(false);
          setMerchantDetail(undefined);
        }}>

        {loading ? renderLoading() : helpOpen ? renderHelp() : merchantDetail ? renderStoreList() : renderMerchantList()}
      </MiniDialog>
    </>
  );
}
