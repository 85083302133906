import { AxiosError, CancelToken } from 'axios';

import { HttpRequestDelete, HttpRequestGet, HttpRequestPost, HttpRequestPut } from './genericService';

interface GetMerchantsResponseModel {
  Result: Merchant[];
  Count: number;
}

interface GetMerchantsRequestModel {
  Query: string;
  Size: number;
  Page: number;
}

export function GetMerchantsRequest(token: string, body: GetMerchantsRequestModel, success: (response: ApiResponse<GetMerchantsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetMerchantsResponseModel, GetMerchantsRequestModel>(
    '/merchant/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    undefined,
    true
  );
}

export interface GroupedMerchant {
  GroupName: string;
  Result: MerchantListModel[];
}

interface ListMerchantsResponseModel {
  Result: GroupedMerchant[];
  Count: number;
}

interface ListMerchantsRequestModel {
  Query: string;
  Size: number;
  Page: number;
}

export function ListMerchantsRequest(token: string, body: ListMerchantsRequestModel, success: (response: ApiResponse<ListMerchantsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<ListMerchantsResponseModel, ListMerchantsRequestModel>(
    '/merchant/list',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}

interface NewMerchantRequestModel {
  MerchantName: string;
  Logo?: string;
  PayerCode: string;
  Audience: string;
  AddressLine1: string;
  AddressLine2?: string;
  City: string;
  PostCode: string;
  County: string;
  Phone: string;
  Email?: string;
  Latitude?: number;
  Longitude?: number;
}

export function AddMerchantRequest(token: string, body: NewMerchantRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, NewMerchantRequestModel>(
    '/merchant',
    body,
    (response) => success(response.data),
    (error: AxiosError) => failure(error),
    token
  );
}

type GetMerchantResponseModel = {
  Merchant: Merchant;
};

export function GetMerchantRequest(token: string, guid: string, success: (response: Merchant) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetMerchantResponseModel>(
    `/merchant/${guid}`,
    (response) => success(response.data.Result.Merchant),
    (error) => failure(error),
    token
  );
}
export function DeleteMerchantRequest(token: string, guid: GUID, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete(
    `/merchant/${guid}`,
    () => success(),
    (error: AxiosError) => failure(error),
    token
  );
}

export type MerchantEditRequestModel = {
  Id: string;
  Name: string;
  Logo: string;
  PayerCode: string;
  AddressLine1: string;
  AddressLine2?: string;
  City: string;
  PostCode: string;
  County: string;
  Phone: string;
  Email: string;
  Audience: string;
};
export function EditMerchantRequest(token: string, body: MerchantEditRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPut<unknown, MerchantEditRequestModel>(
    '/merchant/edit',
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    token
  );
}
export function GetMerchantUsersRequest(token: string, merchantId: string, success: (response: MerchantUser[]) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<MerchantUser[]>(
    `/merchant/${merchantId}/users`,
    (response) => success(response.data.Result),
    (error: AxiosError) => failure(error),
    token
  );
}

export function ListMerchantsWithManagersRequest(token: string, success: (response: ApiResponse<MerchantWithManagers[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<MerchantWithManagers[]>(
    '/merchant/managers',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface ListAssignMerchantsRequestModel {
  Query: string;
  Size: number;
  Page: number;
  OrderBy: string;
}

export interface ListAssignMerchantsModel {
  Group: string;
  Merchants: MerchantListModel[];
}
export interface ListAssignMerchantsResponseModel {
  Merchants: ListAssignMerchantsModel[];
  Count: number;
}

export function ListAssignMerchantsRequest(token: string, body: ListAssignMerchantsRequestModel, success: (response: ApiResponse<ListAssignMerchantsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<ListAssignMerchantsResponseModel, ListAssignMerchantsRequestModel>(
    '/salesmanager/list-merchants',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}
export function ListAssignMerchantsWithSMIdRequest(token: string, salesManagerId: string, body: ListAssignMerchantsRequestModel, success: (response: ApiResponse<ListAssignMerchantsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<ListAssignMerchantsResponseModel, ListAssignMerchantsRequestModel>(
    `/salesmanager/${salesManagerId}/list-merchants`,
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}

interface ListAssignStoresRequestModel {
  MerchantId: GUID;
  Query: string;
  OrderBy: string;
}

export interface AssignStoreItem extends Store {
  FirstName: string;
  LastName: string;
}

export interface AssignStoreListItem {
  Group: string;
  Stores: AssignStoreItem[];
}

export interface ListAssignStoresResponseModel {
  Result: AssignStoreListItem[];
}

export function ListAssignStoresRequest(token: string, body: ListAssignStoresRequestModel, success: (response: ApiResponse<AssignStoreListItem[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<AssignStoreListItem[], ListAssignStoresRequestModel>(
    '/salesmanager/merchant-stores',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}

export interface SubmitAssignMerchantsRequestModel {
  StoreIds?: string[];
  MerchantIds?: string[];
}

interface SubmitListAssignStoresResponseModel {
  Result: GroupedMerchant[];
  Count: number;
}

export function SubmitAssignMerchantsRequest(token: string, body: SubmitAssignMerchantsRequestModel, success: (response: ApiResponse<SubmitListAssignStoresResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<SubmitListAssignStoresResponseModel, SubmitAssignMerchantsRequestModel>(
    '/salesmanager/assign-merchants',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}

interface ListTerritoryManagersRequestModel {
  Query: string,
  Size?: number,
  Page: number,
  OrderBy: 'asc' | 'desc';
}

export interface TerritoryManagerListModel {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  IsSelected: boolean;
}

export interface GroupedTerritoryManagers {
  Group: string;
  TerritoryManagers: TerritoryManagerListModel[];
}

export interface ListTerritoryManagersResponseModel {
  Result: GroupedTerritoryManagers[];
  Count: number;
}

export function ListTerritoryManagersRequest(token: string, body: ListTerritoryManagersRequestModel, success: (response: ApiResponse<ListTerritoryManagersResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<ListTerritoryManagersResponseModel, ListTerritoryManagersRequestModel>(
    '/salesmanager/list-territorymanagers',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}

export function ListTerritoryManagersWithSMIdRequest(token: string, salesmanagerId: string, body: ListTerritoryManagersRequestModel, success: (response: ApiResponse<ListTerritoryManagersResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<ListTerritoryManagersResponseModel, ListTerritoryManagersRequestModel>(
    `/salesmanager/${salesmanagerId}/list-territorymanagers`,
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}

export interface SubmitAssignTerritoryManagerRequestModel {
  TerritoryManagerIds: GUID[];
}

interface AssignTerritoryManagerResponseModel {
  Result: string;
}

export function SubmitAssignTerritoryManagerRequest(token: string, body: SubmitAssignTerritoryManagerRequestModel, success: (response: ApiResponse<AssignTerritoryManagerResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<AssignTerritoryManagerResponseModel, SubmitAssignTerritoryManagerRequestModel>(
    '/salesmanager/assign-territorymanagers',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken,
    true
  );
}

export interface SelectedMerchantsResultModel {
  MerchantId: GUID;
  Stores: GUID[];
  IsSelected: boolean;
}

export interface GetSelectedMerchantsResponseModel {
  Result: SelectedMerchantsResultModel[];
}

export function GetSelectedMerchants(token: string, success: (response: ApiResponse<GetSelectedMerchantsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetSelectedMerchantsResponseModel>(
    '/salesmanager/selected-merchants',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetSelectedMerchantsWithSMId(token: string, salesManagerId: string, success: (response: ApiResponse<GetSelectedMerchantsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetSelectedMerchantsResponseModel>(
    `/salesmanager/${salesManagerId}/selected-merchants`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

type GetSelectedTerritoryManagersResponseModel = GUID[];

export function GetSelectedTerritoryManagers(token: string, success: (response: ApiResponse<GetSelectedTerritoryManagersResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetSelectedTerritoryManagersResponseModel>(
    '/salesmanager/selected-territory-managers',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
