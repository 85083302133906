const INITIAL: SalesManagerState = {
  data: [],
  loading: false,
};

export const salesManagersReducer = (state: SalesManagerState = INITIAL, action: SalesManagerAction): SalesManagerState => {
  switch (action.type) {
    case 'SET_SALES_MANAGERS':
      return { ...state, data: action.payload.currentData };
    case 'CLEAR_SALES_MANAGERS':
      return { ...state, data: [] };
    case 'SET_SALES_MANAGERS_LOADING':
      return { ...state, loading: action.payload.currentData };
    default:
      return state;
  }
};
