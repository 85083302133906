/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import { CircularProgress, Divider, FormHelperText, IconButton, Stack, Typography } from '@mui/material';

import { SalesManagerFormTypes, SALES_MANAGER_FORM } from '../../forms/salesManager';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { EditSalesManagerRequest, GetSalesManagerRoles, SalesManagerTerritoryManager, SearchSalesManagerResult } from '../../service/adminService';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { IStyles } from '../../constants/theme';
import SaveChanges from './SaveChanges';
import { CustomSubmitModel, MerchantAssignModal } from '../MerchantAssignModal';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { TerritoryManagerAssignModal } from '../TerritoryManagerAssignModal';
import { GetSelectedMerchantsWithSMId, SelectedMerchantsResultModel, SubmitAssignMerchantsRequestModel, SubmitAssignTerritoryManagerRequestModel } from '../../service/merchantsService';
import { OverlineSecondary } from '../../components-atoms/TypographyComponents';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { GetStoreListModel, GetStoreListRequest } from '../../service/storeService';

interface AssignedTerritoryManagers {
  requestModel: SubmitAssignTerritoryManagerRequestModel;
  displayNames: { id: string, name: string; }[];
}

const convertToAssignedTerritoryManagers = (tm?: SalesManagerTerritoryManager[]): AssignedTerritoryManagers | undefined => {
  if (!tm) return;
  const requestModel: SubmitAssignTerritoryManagerRequestModel = {
    TerritoryManagerIds: tm.map(x => x.Id)
  };
  const displayNames = tm.map(x => ({ id: x.Id, name: `${x.FirstName} ${x.LastName}` }));
  return { requestModel, displayNames };
};

interface AssignedMerchants {
  requestModel: CustomSubmitModel;
  displayNames: { id: string; type: string; name: string; }[];
}

type GeneralType = {
  [key: string]: string;
};

export function EditSalesManager(): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const salesManager: SearchSalesManagerResult = state.salesmanager;

  const [assignedMerchants, setAssignedMerchants] = useState<AssignedMerchants | undefined>();
  const [assignedTerritoryManagers, setAssignedTerritoryManagers] = useState<AssignedTerritoryManagers | undefined>(
    convertToAssignedTerritoryManagers(salesManager.TerritoryManagers)
  );

  const [storeNames, setStoreNames] = useState<GetStoreListModel[]>([]);


  console.warn({ assignedMerchants });

  const defaultValues: GeneralType = {
    firstName: salesManager.FirstName,
    lastName: salesManager.LastName,
    phone: salesManager.Phone,
    email: salesManager.Email,
    role: ''
  };

  useEffect(() => {
    if (token) {
      GetStoreListRequest(
        token,
        (response) => {
          if (response) {
            setStoreNames(response);
          }
        },
        () => {
          setStoreNames([]);
        }
      );
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      GetSelectedMerchantsWithSMId(
        token,
        salesManager.Id,
        (response) => {
          if (response) {
            const merchantIds: GUID[] = [];
            const storeIds: { [key: string]: GUID[]; } = {};

            if (Array.isArray(response.Result)) {
              response.Result.forEach((m: SelectedMerchantsResultModel) => {
                console.warn({ m });
                if (m.IsSelected) {
                  merchantIds.push(m.MerchantId);
                }

                console.warn({ mStores: m.Stores });

                if (m.Stores.length > 0) {
                  storeIds[m.MerchantId] = m.Stores;
                }
              });
            }

            setAssignedMerchants({
              requestModel: { MerchantIds: merchantIds, StoreIds: storeIds },
              displayNames: []
            });
          }
        },
        () => {
          setAssignedMerchants(undefined);
        }
      );
    }
  }, [salesManager.Id, token]);

  const { control, formState: { isValid, isDirty }, handleSubmit, setValue, reset } = useForm({ mode: 'all', reValidateMode: 'onBlur', defaultValues });

  const assignTerritoryManagers = (requestModel: SubmitAssignTerritoryManagerRequestModel, displayNames: { id: string, name: string; }[]) => {
    setAssignedTerritoryManagers({ requestModel, displayNames });
    setValue('AssignedTerritoryManagers', JSON.stringify(requestModel), { shouldDirty: true });
  };

  const assignMerchants = (requestModel: CustomSubmitModel, displayNames: { id: string; type: string; name: string; }[]) => {
    const requestModel2: CustomSubmitModel = {
      MerchantIds: requestModel.MerchantIds,
      StoreIds: requestModel.StoreIds
    };

    setAssignedMerchants({ requestModel: requestModel2, displayNames });
    setValue('AssignedMerchants', JSON.stringify(requestModel), { shouldDirty: true });
  };

  const convertCustomSubmitModelToRequestModel = (customSubmitModel?: CustomSubmitModel): SubmitAssignMerchantsRequestModel | undefined => {
    if (customSubmitModel === undefined || (customSubmitModel.MerchantIds === undefined && customSubmitModel.StoreIds === undefined)) {
      return undefined;
    }

    const requestModel: SubmitAssignMerchantsRequestModel = {
      MerchantIds: customSubmitModel.MerchantIds,
      StoreIds: customSubmitModel.StoreIds ? Object.entries(customSubmitModel.StoreIds).map(x => x[1]).flat() : undefined
    };

    return requestModel;
  };

  const unLinkTM = (index: number) => {
    const id = assignedTerritoryManagers?.requestModel.TerritoryManagerIds[index];
    delete assignedTerritoryManagers?.requestModel.TerritoryManagerIds[index];
    const newDisplayNames = assignedTerritoryManagers?.displayNames.filter(x => x.id !== id) || [];

    assignTerritoryManagers({
      TerritoryManagerIds: assignedTerritoryManagers?.requestModel.TerritoryManagerIds.map(t => t) || []
    }, newDisplayNames);
  };

  const unlinkMerchantorStore = (id: string, type: string) => {
    let MerchantIds = assignedMerchants?.requestModel.MerchantIds;
    let StoreIds = assignedMerchants?.requestModel.StoreIds;
    const newDisplayNames = assignedMerchants?.displayNames || [];

    switch (type) {
      case 'Merchant':
        if (MerchantIds) {
          const merchantIdIndex = MerchantIds.findIndex(x => x === id as GUID);
          MerchantIds.splice(merchantIdIndex, 1);
          newDisplayNames.splice(newDisplayNames.findIndex(x => x.id === id), 1);
        }
        break;
      case 'Store':
        if (StoreIds) {
          // find merchant id for store id in StoreIds
          let merchantId: string | undefined;
          Object.entries(StoreIds).map(([key, value]) => {
            if (value.includes(id as GUID)) {
              merchantId = key;
            }
          });

          if (merchantId) {
            const storeIdIndex = StoreIds[merchantId].findIndex(x => x === id);
            StoreIds[merchantId].splice(storeIdIndex, 1);
            newDisplayNames.splice(newDisplayNames.findIndex(x => x.id === id), 1);

            if (StoreIds[merchantId].length === 0) {
              delete StoreIds[merchantId];
            }
          }
        }
        break;
    }

    if (MerchantIds && MerchantIds.length === 0) {
      MerchantIds = undefined;
    }

    if (StoreIds && Object.keys(StoreIds).length === 0) {
      StoreIds = undefined;
    }

    assignMerchants({
      MerchantIds,
      StoreIds
    }, newDisplayNames);
  };

  const renderAssignment = (index: number, id: string, type: string, name: string | undefined, isLast: boolean) => {
    return (
      <Fragment key={index}>
        <Grid container flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap={{ xs: 'nowrap', sm: 'unset' }} sx={{ flexShrink: 1, flexGrow: 1, minHeight: '80px' }}>
          <Grid item xs={3.2} sx={{ width: { xs: '100%' } }}>
            <OverlineSecondary>{type}</OverlineSecondary>
          </Grid>

          <Grid item xs>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography>{name}</Typography>
              <IconButton onClick={() => type === 'Territory Manager' ? unLinkTM(index) : unlinkMerchantorStore(id, type)}>
                <RemoveCircleIcon
                  color='primary'
                  fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        {!isLast ? <Divider /> : null}
      </Fragment>
    );
  };

  useEffect(() => {
    if (token) {
      setOptionsLoading(true);
      GetSalesManagerRoles(
        token,
        (response) => {
          setOptionsLoading(false);
          response.Result.map(res => {
            setOptions(((prev: any) => [...prev, { value: res.Id, name: res.Name }]));
            if (res.Name === salesManager.Role) {
              reset({ ...defaultValues, role: res.Id });
            }
          });
        },
        () => {
          setOptionsLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submit: SubmitHandler<SalesManagerFormTypes> = (data): void => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);
      EditSalesManagerRequest(
        token,
        {
          Id: salesManager.Id,
          FirstName: data.firstName,
          LastName: data.lastName,
          Phone: data.phone,
          SalesManagerRoleId: data.role,
          AssignedMerchants: convertCustomSubmitModelToRequestModel(assignedMerchants?.requestModel),
          AssignedTerritoryManagers: assignedTerritoryManagers?.requestModel
        },
        () => {
          navigate('/admin/salesmanagers', { state: { from: 'edit-salesmanager' } });
        },
        () => {
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const handleClose = () => {
    if (isDirty) {
      setShowSaveModal(true);
      return;
    }

    navigate('/admin/salesmanagers', { state: { from: 'edit-salesmanager' } });
  };

  const onSaveChangesSubmit = () => {
    setShowSaveModal(false);
    navigate('/admin/salesmanagers', { state: { from: 'edit-salesmanager' } });
  };

  const getModalStoresAndMerchantsList = () => {
    const list: { id: GUID; type: string; name: string; }[] = [];

    if (assignedMerchants && assignedMerchants.requestModel.MerchantIds) {
      assignedMerchants.requestModel.MerchantIds.map((merchantId) => {
        const store = storeNames.find(x => x.MerchantId === merchantId);
        const merchantName = store?.MerchantName;
        list.push({ id: merchantId as GUID, type: 'Merchant', name: merchantName || '' });
      });
    }

    if (assignedMerchants && assignedMerchants.requestModel.StoreIds) {
      Object.entries(assignedMerchants.requestModel.StoreIds).map(([, storeIds]) => {
        storeIds.map((storeId) => {
          const store = storeNames.find(x => x.StoreId === storeId);
          const storeName = store?.StoreName;
          const merchantName = store?.MerchantName;
          list.push({ id: storeId, type: 'Store', name: `${merchantName} — ${storeName}` });
        });
      });
    }

    return list;
  };

  const hasAssignedStoresOrMerchants = (assignedMerchants && assignedMerchants.requestModel.MerchantIds && assignedMerchants.requestModel.MerchantIds.length > 0)
    || (assignedMerchants && assignedMerchants.requestModel.StoreIds && Object.keys(assignedMerchants.requestModel.StoreIds).length > 0);

  return (
    <Fragment>
      {optionsLoading ? (
        <Grid container item justifyContent="center" alignItems="center">
          <CircularProgress
            color="primary"
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>
      ) : (
        <FormPageComponent
          title="Edit Sales Manager"
          buttonTitle="Submit"
          buttonDisabled={!isValid || loading}
          close={handleClose}
          submit={handleSubmit(submit)}
          scrollToTop={scrollToTop}
        >
          <Fragment>
            {SALES_MANAGER_FORM.map((section, index) => {
              return (
                <ModalCardComponent key={index}>
                  <SectionHeader title={section.title} />
                  <Fragment>
                    {section.fields.map((field, idx) =>
                      <Grid key={idx}>
                        {field.inputType === 'select'
                          ? <FormControlSelect control={control} field={{ ...field, options }} />
                          : <FormControlInput disabled={field.name === 'email'} control={control} field={field} />
                        }
                      </Grid>
                    )}
                    <div style={styles.helperTextContainer}>
                      <FormHelperText>
                        Example: 07975777666
                      </FormHelperText>
                    </div>
                  </Fragment>
                </ModalCardComponent>
              );
            })}
          </Fragment>
          {loading ? (
            <div style={styles.overlay}>
            </div>
          ) : (
            <></>
          )}

          <ModalCardComponent>
            <SectionHeader
              title='Assign Merchant or Stores'
              button={
                <MerchantAssignModal
                  customTitle='Manage Assignments'
                  onCustomSubmit={assignMerchants}
                  initialSelected={{
                    Merchants: (assignedMerchants?.requestModel.MerchantIds || []).map(x => x as GUID),
                    Stores: assignedMerchants?.requestModel.StoreIds
                  }}
                  getListWithSMId={salesManager.Id}
                />}
            />

            {hasAssignedStoresOrMerchants
              ? <Fragment>
                {
                  getModalStoresAndMerchantsList().map((obj, index) =>
                    renderAssignment(
                      index,
                      obj.id,
                      obj.type,
                      obj.name,
                      index === assignedMerchants.displayNames.length - 1
                    )
                  )
                }
              </Fragment>
              : <InfoComponent title="" description="No Merchants or Stores are assigned" />
            }
          </ModalCardComponent>

          <ModalCardComponent>
            <SectionHeader
              title='Assign Territory Managers'
              button={
                <TerritoryManagerAssignModal
                  customTitle='Manage Assignments'
                  onCustomSubmit={assignTerritoryManagers}
                  initialSelected={assignedTerritoryManagers?.requestModel.TerritoryManagerIds}
                  getListWithSMId={salesManager.Id}
                />}
            />

            {assignedTerritoryManagers && assignedTerritoryManagers.requestModel.TerritoryManagerIds.length > 0
              ? <Fragment>
                {assignedTerritoryManagers.requestModel.TerritoryManagerIds.map((id, index) =>
                  renderAssignment(
                    index,
                    id,
                    'Territory Manager',
                    assignedTerritoryManagers.displayNames.find(x => x.id === id)?.name,
                    index === assignedTerritoryManagers.requestModel.TerritoryManagerIds.length - 1
                  )
                )}
              </Fragment>
              : <InfoComponent title="" description="No Territory Managers are assigned" />
            }
          </ModalCardComponent>
        </FormPageComponent>
      )}

      <SaveChanges
        visible={showSaveModal}
        onCancel={() => setShowSaveModal(false)}
        onSubmit={onSaveChangesSubmit}
      />
    </Fragment>
  );
}

const styles: IStyles = {
  helperTextContainer: {
    padding: '0 14px'
  },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
