import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { HeaderSix, BodyTwoPrimary, BodyTwoSecondary, HeaderFive, BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { ButtonContainedPrimary, ButtonTextPrimary } from '../components-atoms/ButtonComponents';
import { Avatar, Container, TextField, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LIGHT_THEME } from '../constants/theme';
import SearchIcon from '@mui/icons-material/Search';
import { CenteredDialogComponent } from './dialogs/CenteredDialog';


export interface IFilterOption { title: string, value: string; }

export interface IFilterOptions { [key: string]: IFilterOption[]; }

interface IFilterMenuProps {
  activeButtons: boolean | undefined;
  filterOptions: IFilterOptions;
  sizeKey?: string;
  onSubmit: (selected: ISelectedOptions, sizeFrom?: string, sizeTo?: string) => void;
  isAdmin?: boolean;
  customFilterElement?: JSX.Element;
  reset?: boolean;
  setReset?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultFilter?: ISelectedOptions;
  showSearch?: boolean;
}

export interface ISelectedOptions { [key: string]: string[] | any[]; }

export function FilterMenu({ activeButtons, filterOptions, onSubmit, sizeKey, isAdmin, customFilterElement, reset, setReset, defaultFilter, showSearch }: IFilterMenuProps): JSX.Element {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [value, setValue] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFilters, setSelectedFilters] = useState<ISelectedOptions>(defaultFilter || {});
  const [submittedFilter, setSubmittedFilter] = useState<ISelectedOptions>(defaultFilter || {});
  const [sizeFrom, setSizeFrom] = useState('');
  const [sizeTo, setSizeTo] = useState('');
  const [dateValueStartFrom, setDateValueFrom] = useState<null | string>(null);
  const [dateValueStartTo, setDateValueTo] = useState<null | string>(null);
  const [searchFilter, setSearchFilter] = useState('');
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };

  const handleClose = () => {
    setAnchorEl(null);
    setValue(0);
  };

  const applyFilters = (filterToApply = selectedFilters) => {
    setSubmittedFilter(filterToApply);
    handleClose();
    if (sizeKey && Object.values(filterToApply).some(s => s.includes(sizeKey))) {
      onSubmit(filterToApply, sizeFrom, sizeTo);
    }
    onSubmit(filterToApply);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    let temp: string[] = selectedFilters[selectedOption] ? [...selectedFilters[selectedOption]] : [];
    if (event.target.checked) {
      temp.push(value);
    } else {
      temp = selectedFilters[selectedOption].filter(f => f !== value);
    }
    setSelectedFilters({ ...selectedFilters, [selectedOption]: [...temp] });
  };
  useEffect(() => {
    if (reset) {
      clearAll();
      setReset && setReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);
  const filterCount = Object.values(submittedFilter).map(f => f.length).reduce((a, b) => a + b, 0);
  const filterBadgeWidth = document?.getElementById('filterBadge')?.clientWidth || 0;

  const isCustomSelected = (): boolean => {
    return sizeKey ? Object.values(selectedFilters).some(f => f.includes(sizeKey)) : false;
  };

  const clearAll = () => {
    if (value === 1) {
      setSelectedFilters(prev => ({ ...prev, [selectedOption]: [] }));
      applyFilters({ ...selectedFilters, [selectedOption]: [] });
    } else {
      let temp = selectedFilters;
      Object.keys(selectedFilters).map(key => {
        temp = { ...temp, [key]: [] };
      }
      );
      setSelectedFilters(temp);
      setDateValueFrom(null);
      setDateValueTo(null);
      applyFilters(temp);
    }
  };

  const selectAll = () => {
    const temp = filterOptions[selectedOption].map(
      filterItem => filterItem.value
    );
    setSelectedFilters(prev => ({ ...prev, [selectedOption]: temp }));
  };

  const filteredOptions = filterOptions[selectedOption]
    ?.filter(e => e.title.toLowerCase()
      .includes(searchFilter.toLowerCase()));

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <ButtonTextPrimary
            onClick={handleClick}
            startIcon={<FilterListIcon sx={{ width: '24px', height: '24px' }} color={(activeButtons) ? 'primary' : 'inherit'} />}
            sx={{ margin: filterCount ? `0 ${isAdmin ? (8 + filterBadgeWidth / 2) : (filterBadgeWidth / 2)}px 0 0` : isAdmin ? '0 8px 0 0' : '0', padding: '8px 11px !important' }}
            aria-label="filter list"
            aria-controls={open ? 'filter-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {filterCount ? (
              <div
                id="filterBadge"
                style={{
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  margin: `0px -${filterBadgeWidth / 2}px 0px 0px`,
                  padding: '0 6.5px',
                  minWidth: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#00B495',
                  borderRadius: '10px',
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    lineHeight: 1.25,
                    letterSpacing: '0.00875em'
                  }}
                >
                  {filterCount}
                </Typography>
              </div>
            ) : (
              <></>
            )}
            Filter
          </ButtonTextPrimary>

        </Box>
        <Menu
          anchorEl={anchorEl}
          id="filter-menu"
          open={open}
          onClose={() => applyFilters()}
          PaperProps={{
            elevation: 0,
            sx: {
              width: '320px',
              height: '560px',
              background: 'white',
              boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12)',
              borderRadius: '12px',
              overflow: 'hidden'
            },
          }}
          MenuListProps={{
            sx: {
              height: '100%',
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Grid container sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '0px',
            height: '100%',
            flexWrap: 'nowrap'
          }}>
            {(value === 0) ? (
              <>
                <Grid>
                  <Grid item sx={{ padding: '24px 16px 16px' }}>
                    <HeaderSix style={{ margin: 0 }}>Filter</HeaderSix>
                  </Grid>
                  <Grid sx={{ paddingLeft: '4px' }}>
                    <ButtonTextPrimary onClick={() => clearAll()} disabled={Object.values(selectedFilters).every(e => e.length === 0)}>Clear all</ButtonTextPrimary>
                  </Grid>
                </Grid>
                <Grid sx={{ overflowY: 'auto', height: '100%' }}>
                  {Object.keys(filterOptions).map(key =>
                    (filterOptions[key] && filterOptions[key].length > 0) || key === 'Date Range' ? (
                      <MenuItem key={key} sx={{ alignItems: 'center', justifyContent: 'space-between', padding: '14px 16px' }} onClick={() => { setSelectedOption(key); setValue(1); }}>
                        <Grid sx={{ width: '90%' }}>
                          <BodyTwoPrimary>{key}</BodyTwoPrimary>
                          <BodyTwoSecondary sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%'
                          }}>
                            {
                              filterOptions[key].filter(f => selectedFilters[key]?.includes(f.value)).map(m => m.title).join(', ')
                            }</BodyTwoSecondary>
                        </Grid>
                        <ArrowForwardIosIcon sx={{ fontSize: '0.75rem' }} />
                      </MenuItem>
                    ) : null)}
                  {customFilterElement ? (
                    <MenuItem sx={{ alignItems: 'center', justifyContent: 'space-between', padding: '15px 16px' }}>
                      {customFilterElement}
                    </MenuItem>
                  ) : null}
                </Grid>
              </>
            ) : (
              <>
                <Grid>
                  <Grid item sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <ArrowBackIcon sx={{ cursor: 'pointer' }} onClick={() => setValue(0)} />
                    <HeaderSix style={{ margin: '0 0 0 32px' }}>
                      {selectedOption}
                    </HeaderSix>
                  </Grid>
                  <Grid sx={{ paddingLeft: '4px' }}>
                    {selectedOption !== 'Date Range'
                      ? (
                        <>
                          <ButtonTextPrimary onClick={() => selectAll()}>Select all</ButtonTextPrimary>
                          <ButtonTextPrimary onClick={() => clearAll()} disabled={selectedFilters[selectedOption]?.length === 0}>Clear all</ButtonTextPrimary>
                        </>
                      )
                      : <></>
                    }
                  </Grid>
                </Grid>
                <Grid sx={{ overflowY: 'auto', height: '100%', marginBottom: '16px' }}>

                  {selectedOption === 'Date Range'
                    ? (
                      <React.Fragment >
                        <Container sx={{ padding: '15px 16px' }}>
                          <DatePicker
                            label="From"
                            mask="__-__-____"
                            value={dateValueStartFrom}
                            inputFormat="dd-MM-yyyy"
                            onChange={(newValue) => {
                              setDateValueFrom(newValue);
                              const temp: any[] = [newValue, dateValueStartTo];
                              setSelectedFilters({ ...selectedFilters, [selectedOption]: [...temp] });
                            }}
                            renderInput={(params) =>
                              <TextField
                                sx={{ width: '100%', marginBottom: '16px' }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: ''
                                }}
                              />
                            }
                          />
                          <DatePicker
                            label="To"
                            mask="__-__-____"
                            value={dateValueStartTo}
                            inputFormat="dd-MM-yyyy"
                            onChange={(newValue) => {
                              setDateValueTo(newValue);
                              const temp: any[] = [dateValueStartFrom, newValue];
                              setSelectedFilters({ ...selectedFilters, [selectedOption]: [...temp] });
                            }}
                            renderInput={(params) =>
                              <TextField
                                sx={{ width: '100%' }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: ''
                                }}
                              />
                            }
                          />
                        </Container>
                      </React.Fragment>
                    )
                    : <Box>
                      {(showSearch && filterOptions?.[selectedOption]?.length > 2) && <TextField
                        size='small'
                        variant='filled'
                        label="Search"
                        placeholder="Search"
                        sx={{ width: '90%', mt: 2, ml: 2, mb: 2 }}
                        value={searchFilter}
                        onChange={(el) => setSearchFilter(el.target.value)}
                      />}

                      {filteredOptions?.length === 0 && <CenteredDialogComponent sx={{ mt: 4, width: '100%' }}>
                        <Avatar sx={{ height: '120px', width: '120px', background: LIGHT_THEME.palette.action.selected }}>
                          <SearchIcon
                            sx={{
                              fontSize: 60,
                              fill: LIGHT_THEME.palette.primary.main,
                            }}
                          />
                        </Avatar >
                        <HeaderFive sx={{ textAlign: 'center', marginTop: '32px' }}>No result found</HeaderFive>
                        <BodyOneSecondary sx={{ textAlign: 'center' }}>No result found. Try changing your search query.</BodyOneSecondary>
                      </CenteredDialogComponent >
                      }
                      {filteredOptions.map(elem => (
                        <React.Fragment key={elem.value}>
                          <MenuItem sx={{ padding: '15px 16px' }}>
                            <FormGroup>
                              <FormControlLabel
                                sx={{
                                  margin: 0,
                                  wordBreak: 'break-word',
                                  width: '100%',
                                  whiteSpace: 'break-spaces'
                                }}
                                control={
                                  <Checkbox
                                    sx={{ padding: 0, margin: '0 23px 0 9px' }}
                                    checked={(selectedOption in selectedFilters) && selectedFilters[selectedOption].includes(elem.value)}
                                    onChange={(event) => handleChange(event, elem.value)}
                                  />
                                }
                                label={elem.title}
                              />
                            </FormGroup>
                          </MenuItem>
                          {sizeKey
                            && selectedFilters[selectedOption]
                            && selectedFilters[selectedOption].includes(sizeKey)
                            && elem.value === sizeKey
                            && (
                              <Grid item container sx={{ padding: '10px' }}>
                                <TextField
                                  fullWidth
                                  variant="filled"
                                  label="From"
                                  value={sizeFrom}
                                  onChange={e => {
                                    if (e.target.value !== '' && !+e.target.value) {
                                      return;
                                    }
                                    setSizeFrom(e.target.value);
                                  }
                                  }
                                />
                                <TextField
                                  sx={{ marginTop: '10px' }}
                                  fullWidth
                                  variant="filled"
                                  label="To"
                                  value={sizeTo}
                                  onChange={e => {
                                    if (e.target.value !== '' && !+e.target.value) {
                                      return;
                                    }
                                    setSizeTo(e.target.value);
                                  }
                                  }
                                />
                              </Grid>
                            )}
                        </React.Fragment>
                      ))}
                    </Box>
                  }
                </Grid>
              </>
            )}
            <Grid item sx={{ alignSelf: 'center' }}>
              <ButtonContainedPrimary
                style={{ width: smallDown ? '100%' : '288px', height: '36px' }}
                disabled={isCustomSelected() && !(sizeFrom && sizeTo)}
                onClick={() => applyFilters()} >Apply Filters</ButtonContainedPrimary>
            </Grid>
          </Grid>
        </Menu>
      </LocalizationProvider>
    </>
  );
}
