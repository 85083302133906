import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { useEffect, useState } from 'react';
import { CircularProgress, Stack, Grid } from '@mui/material';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { gaService } from '../../service/gaService';
import { GetRequest, LogAction, LogActionType } from '../../service/digitalToolkitService';
import { Footer } from '../Footer';
import { ListingCard } from '../cards/listing/ListingCard';
import { ButtonContainedPrimary, ButtonOutlinedPrimary } from '../../components-atoms/ButtonComponents';
import { FullScreenAssetDialog, FullScreenDialogServiceType } from '../../components-molecules/dialogs/FullScreenAssetDialog';
import DetailPageHeader from '../appbars/DetailPageHeader';
import { GetAllServicesNoPublicRequest, GetAvailableServices } from '../../service/serviceService';

interface IDigitalToolkitDetailProps {
  id?: string;
}

export function DigitalToolkitDetail({ id }: IDigitalToolkitDetailProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const { state } = useLocation();
  const { toolkitId = id } = useParams();
  const [fullScreenModalOpen, setFullScreenModalOpen] = useState<number>(state?.modalIndex || 0);
  const [token, userServices] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.services]);
  const [loading, setLoading] = useState(!state?.product);
  const [toolkit, setToolkit] = useState(state?.product);
  const user = useTypedSelector((state) => state.userReducer.data);
  const isAdmin = user?.Role === 'Super Admin';

  const getDigitalToolkit = () => {
    if (token && toolkitId) {
      setLoading(true);
      GetRequest(
        token,
        toolkitId,
        (response) => {
          if (response) {
            setToolkit(response.Result);
            setLoading(false);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {
          navigate('/unauthorized');
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (!state?.product) {
      getDigitalToolkit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gaService.pageView('Digital Toolkit Detail');
  }, []);

  const onBack = () => {
    const serviceUrl = '/service/';
    let url = '';

    if (isAdmin && token) {
      GetAllServicesNoPublicRequest(
        token,
        (resp) => {
          const digitalAssetServiceId = resp.Result.flatMap(f => f.Services).find(m => m.ServiceName === 'Digital Toolkits')?.ServiceId;
          dispatch({ type: 'DRAWER_SET_SERVICE_LIST', payload: { services: resp.Result } });
          navigate('/admin/servicedetail/' + digitalAssetServiceId);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    } else {
      if (userServices?.length > 0) {
        const digitalAssetServiceId = userServices.flatMap(f => f.Services).find(m => m.ServiceName === 'Digital Toolkits')?.ServiceId;
        url = `${serviceUrl}${digitalAssetServiceId}`;
        navigate(url);
      } else if (token) {
        GetAvailableServices(
          token,
          (res) => {
            dispatch({ type: 'USER_SET_SERVICES', payload: { services: res.Result } });
            const digitalAssetServiceId = res.Result.flatMap(f => f.Services).find(m => m.ServiceName === 'Digital Toolkits')?.ServiceId;
            url = `${serviceUrl}${digitalAssetServiceId}`;
            navigate(url);
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { }
        );
      }
    }
  };

  const onClose = () => {
    if (!state?.modalIndex) {
      onBack();
      return;
    }

    setFullScreenModalOpen(-1);
  };

  const downloadPdf = () => {
    const fileUrl = toolkit.FileUrl;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = toolkit.FileUrl.split('/').pop() as string;
        downloadLink.click();
        URL.revokeObjectURL(url);
        LogAction(token, toolkit.Id, LogActionType.download, toolkit.FileUrl);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
  };

  function onClick() {
    window.open(`/detail/toolkit/${toolkit.Id}`, '_blank');
    LogAction(token, toolkit.Id, LogActionType.view, toolkit.FileUrl);
  }

  function renderButtons() {
    return (
      <Stack direction="row" spacing={1}>
        <ButtonOutlinedPrimary
          onClick={() => onClick()}
          sx={{ height: '36px' }}>
          View PDF
        </ButtonOutlinedPrimary>
        <ButtonContainedPrimary
          onClick={() => downloadPdf()}
          sx={{ height: '36px' }}>
          Download PDF
        </ButtonContainedPrimary>
      </Stack>
    );
  }

  if (!token) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  if (loading) {
    return (
      <CircularProgress
        color='primary'
        size={50}
        style={{ zIndex: 98, position: 'absolute', top: '50vh', right: '50vw' }}
      />
    );
  }

  function renderHeader() {
    return (
      <DetailPageHeader
        title="Digital Toolkits"
        onBack={onBack} />
    );
  }

  function renderContent() {
    return (
      <Stack
        display='flex'
        flex={1}
        mt={4}
        px={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}>
        <ListingCard
          hideEllipsis
          fromDetail
          isFirst={true}
          isLast={true}
          title={toolkit.Title}
          thumbnailUrl={toolkit.ThumbnailUrl}
          description={toolkit.Description}
          customButtons={() => renderButtons()} />
      </Stack>
    );
  }

  function renderFooter() {
    return (
      <Grid item sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    );
  }

  return (
    <Stack flex={1}>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}

      <FullScreenAssetDialog
        open={fullScreenModalOpen > -1}
        index={fullScreenModalOpen}
        assets={[toolkit]}
        onClose={onClose}
        serviceType={FullScreenDialogServiceType.DigitalToolkit}
      />
    </Stack>
  );
}
