/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { StoreFormTypes, STORE_FORM } from '../../forms/store';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { AssignToStoreRequest, EditStoreRequest, UnassignStoreRequest } from '../../service/storeService';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { FormControlInput } from '../../components-molecules/FormControl';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';
import { ListDialog } from '../../components-molecules/dialogs/MiniDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetTerritoryManagersRequest } from '../../service/adminService';
import { FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import SaveChanges from './SaveChanges';
import { BodyTwoSecondary, OverlineSecondary } from '../../components-atoms/TypographyComponents';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SalesManagersList from './SalesManagersList';

// TODO: Delete this type, instead review controlled inputs
interface GeneralType {
  [key: string]: string;
}

export function EditStore(): JSX.Element {
  const dispatch = useTypedDispatch();
  const [dialog, setDialog] = useState<false | 'territorymanager' | 'salesmanager' | 'storeemployee'>(false);
  const [employeesToUnassign, setEmployeesToUnassign] = useState<string[]>([]);
  const [employeesToAssign, setEmployeesToAssign] = useState<string[]>([]);
  const [currentTerritoryManager, setCurrentTerritoryManager] = useState<TerritoryManager>();
  // const [, setCurrentSalesManager] = useState<SalesManager>();
  const [users, setUsers] = useState<MerchantUser[]>([]);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const [userData, token, currentStore, employeeList, managerList, territoryManager, storeSalesManagers, merchantManagerList, merchantEmployees] = useTypedSelector(state =>
    [
      state.userReducer.data,
      state.userReducer.token,
      state.storeReducer.currentStore,
      state.storeReducer.employeeList,
      state.storeReducer.managerList,
      state.storeReducer.territoryManager,
      state.storeReducer.salesManagers,
      state.storeReducer.merchantManagerList,
      state.merchantReducer.employees
    ]);

  const [tmId, setTmId] = useState<string | undefined>(territoryManager?.AdminProperties.Id);
  const [defaultValues] = useState<GeneralType>(currentStore);
  const { handleSubmit, control, formState: { isValid, isDirty }, reset, trigger } = useForm<GeneralType>({ mode: 'all', reValidateMode: 'onBlur', defaultValues });
  const [loading, setLoading] = useState(false);
  const territoryManagers: TerritoryManager[] = useTypedSelector(state => state.territoryManagersReducer.data);
  const territoryManagersLoading: boolean = useTypedSelector(state => state.territoryManagersReducer.loading);
  const [selectedSalesManagers, setSelectedSalesManagers] = useState<any>([]);

  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();

  const submit: SubmitHandler<StoreFormTypes> = (data): void => {
    if (token) {
      setLoading(true);
      EditStoreRequest(
        token,
        {
          Id: currentStore.id,
          Name: data.name,
          CustomerCode: data.customerCode,
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          City: data.city,
          PostCode: data.postCode,
          County: data.county,
          Phone: data.phone,
          Email: data.email,
          TerritoryManagerId: (tmId && tmId.length > 0) ? tmId as GUID : null,
          SalesManagerIds: selectedSalesManagers
        },
        () => {
          setLoading(false);
          dispatch({ type: 'MERCHANT_REFRESH' });
          navigate(state.navigateBackTo);
        },
        (error) => console.error(error)
      );
      employeesToAssign.map(id => {
        AssignToStoreRequest(
          token,
          {
            StoreId: currentStore.id as GUID,
            UserId: id as GUID,
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { }
        );
      });
      employeesToUnassign.map(id => {
        UnassignStoreRequest(
          token,
          currentStore.id,
          id,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { }
        );
      });
    }
  };

  const handleClose = () => {
    if (isDirty) {
      setShowSaveModal(true);
      return;
    }

    dispatch({ type: 'MERCHANT_REFRESH' }); navigate(state.navigateBackTo);
  };

  const onSaveChangesSubmit = () => {
    setShowSaveModal(false);
    dispatch({ type: 'MERCHANT_REFRESH' }); navigate(state.navigateBackTo);
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    setCurrentTerritoryManager(territoryManager);
  }, [territoryManager]);

  useEffect(() => {
    reset(currentStore);

    setUsers([...managerList.map(manager => ({
      ...manager.UserProperties,
      Role: 'Store Manager'
    })),
    ...employeeList.map(employee => ({
      ...employee.UserProperties,
      Role: 'Store Employee'
    })),
    ...merchantManagerList.map(merchantManager => ({
      ...merchantManager.UserProperties,
      Role: 'Merchant Manager'
    }))
    ]);
  }, [currentStore, reset, employeeList, managerList, merchantManagerList]);



  const addTerritoryManager = (list: Set<string>): void => {
    const tmId = list.values().next().value;
    setTmId(tmId);
    setCurrentTerritoryManager(territoryManagers.find(f => f.AdminProperties.Id === tmId));
    setDialog(false);
  };

  const addStoreEmployees = (list: Set<string>): void => {
    const currentEmployeeIds: string[] = [...employeeList.map(employee => employee.UserProperties.Id), ...managerList.map(employee => employee.UserProperties.Id), ...merchantManagerList.map(employee => employee.UserProperties.Id)];
    const tmpUnassign: string[] = [];
    const tmpAssign: string[] = [];
    currentEmployeeIds.map(employeeId => {
      if (!list.has(employeeId)) {
        tmpUnassign.push(employeeId);
      }
    });
    setEmployeesToUnassign(tmpUnassign);
    list.forEach(val => {
      if (!currentEmployeeIds.includes(val)) {
        tmpAssign.push(val);
      }
    });
    setEmployeesToAssign(tmpAssign);
    setUsers(merchantEmployees.filter(employee => tmpAssign.includes(employee.Id) || (currentEmployeeIds.includes(employee.Id) && !tmpUnassign.includes(employee.Id))));
    setDialog(false);
  };
  const getTerritoryManagers = useCallback(() => {
    if (token) {
      dispatch({ type: 'SET_MANAGERS_LOADING', payload: { currentData: true } });
      GetTerritoryManagersRequest(
        token,
        { Query: query },
        (response) => {
          dispatch({ type: 'SET_MANAGERS', payload: { currentData: response } });
          dispatch({ type: 'SET_MANAGERS_LOADING', payload: { currentData: false } });
        },
        (error) => { console.error(error); }
      );
    }
  }, [query, token, dispatch]);
  const searchTermChanged = (text: string) => {
    setQuery(text);
  };
  useEffect(() => {
    getTerritoryManagers();
  }, [getTerritoryManagers]);
  useEffect(() => {
    dispatch({ type: 'SET_MANAGERS_SEARCH_QUERY', payload: { currentData: query } });
    return () => {
      dispatch({ type: 'SET_MANAGERS_SEARCH_QUERY', payload: { currentData: '' } });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const unLinkTM = () => {
    setTmId(undefined);
    setCurrentTerritoryManager(undefined);
  };

  const onSalesManagerChange = (sm: any) => {
    setSelectedSalesManagers(sm);
  };

  const renderTerritoryManagerItem = (r: any, isLast?: boolean) => {
    return (
      <Fragment key={r.Id}>
        <Grid container flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap={{ xs: 'nowrap', sm: 'unset' }} sx={{ flexShrink: 1, flexGrow: 1, minHeight: '80px' }}>
          <Grid item xs={3.2} sx={{ width: { xs: '100%' } }}>
            <OverlineSecondary>Territory Manager</OverlineSecondary>
          </Grid>

          <Grid item xs>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack>
                <Typography>{r.FirstName} {r.LastName}</Typography>
                <BodyTwoSecondary>
                  {r.Email}
                </BodyTwoSecondary>
              </Stack>
              <IconButton onClick={unLinkTM}>
                <RemoveCircleIcon
                  color='primary'
                  fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        {!isLast ? <Divider /> : null}
      </Fragment>
    );
  };

  return (
    <FormPageComponent title="Edit Store" buttonTitle="Submit" buttonDisabled={!isValid || loading} buttonLoading={loading} close={handleClose} submit={handleSubmit(submit)}>
      <Fragment>
        {STORE_FORM.map((section) => {
          return (
            <ModalCardComponent key={section.title}>
              <SectionHeader title={section.title} />
              <Fragment>
                <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                  {section.fields.map((field) =>
                    <Grid key={field.name} item xs={field.name === 'city' || field.name === 'postCode' ? 6 : 12}>
                      <FormControlInput control={control} field={field} />
                      {field.helperText
                        && <FormHelperText sx={{ padding: '0 14px' }}>
                          {field.helperText}
                        </FormHelperText>
                      }
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            </ModalCardComponent>
          );
        })}
      </Fragment>

      {userData?.Role === 'Store Manager' || userData?.Role === 'Store Employee' || userData?.Role === 'Merchant Manager'
        ? null
        : (
          <ModalCardComponent>
            <SectionHeader
              title="Territory manager"
              button={
                userData
                  ? <ButtonContainedPrimary disabled={territoryManagers.length === 0 || userData.Role === 'Territory Manager'} startIcon={<AddIcon />} onClick={() => setDialog('territorymanager')}>Link Manager</ButtonContainedPrimary>
                  : undefined
              }
            />

            {!currentTerritoryManager
              ? <Grid item sx={{ flexGrow: 1, margin: '16px 0' }}>
                <InfoComponent
                  title="Add Territory Manager"
                  description={territoryManagers.length > 0 ? 'Add Territory Manager to the store.' : 'You haven\'t added any territory managers to this merchant yet.'}
                />
              </Grid>
              : renderTerritoryManagerItem(currentTerritoryManager.AdminProperties)
            }
          </ModalCardComponent>
        )}

      <SalesManagersList
        managers={storeSalesManagers}
        onChange={onSalesManagerChange} />

      <ModalCardComponent>
        <SectionHeader
          title="Store users"
          button={
            <ButtonContainedPrimary disabled={merchantEmployees.length === 0} startIcon={<AddIcon />} onClick={() => setDialog('storeemployee')}>Link Employees</ButtonContainedPrimary>
          }
        />

        {users === undefined || users.length === 0
          ? <Grid item sx={{ flexGrow: 1, margin: '16px 0' }}>
            <InfoComponent
              title="Adding Store Users"
              description={merchantEmployees.length > 0 ? 'Add Store Manager and Store Users to the store.' : 'You haven\'t added any employees to this merchant yet.'}
            />
          </Grid>
          : <Fragment>
            {users.map((employee, index) =>
              <Fragment key={index}>
                <SectionRow
                  title={employee.Role}
                  lines={[
                    { data: `${employee.FirstName} ${employee.LastName}`, type: 'body1' },
                    { data: employee.Email, type: 'body2', color: 'text.secondary' },
                    { data: employee.Phone, type: 'body2', color: 'text.secondary' }
                  ]}
                />
                {users.length - 1 > index ? <Box><Divider /></Box> : null}
              </Fragment>
            )}
          </Fragment>
        }

      </ModalCardComponent>

      <ListDialog
        items={territoryManagers}
        title="Select Territory Manager"
        caption={{ singular: 'Territory Manager', plural: 'Territory Managers' }}
        open={dialog === 'territorymanager'}
        close={() => setDialog(false)}
        submit={addTerritoryManager}
        edit
        hasSearch
        searchTextOnChange={searchTermChanged}
        searchPlaceHolder="Search Territory Manager"
        loading={territoryManagersLoading}
      />

      <ListDialog
        items={merchantEmployees}
        title="Select Store Users"
        caption={{ singular: 'User', plural: 'Users' }}
        open={dialog === 'storeemployee'}
        close={() => setDialog(false)}
        submit={addStoreEmployees}
        multiple
        edit
      />

      <SaveChanges
        visible={showSaveModal}
        onCancel={() => setShowSaveModal(false)}
        onSubmit={onSaveChangesSubmit}
      />
    </FormPageComponent>
  );
}
