import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { ButtonContainedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { Box, Stack } from '@mui/material';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';

interface IShareRestrictedContentProps {
  open: boolean;
  onAccept: () => void;
  onClose: () => void;
}
export default function ShareRestrictedContent({ open, onAccept, onClose }: IShareRestrictedContentProps): JSX.Element {
  function renderButtons() {
    return (
      <Stack direction="row" alignSelf="flex-end" spacing={1} p={3}>
        <ButtonTextPrimary
          onClick={onClose}
          sx={{ height: '36px' }}>
          Cancel
        </ButtonTextPrimary>
        <ButtonContainedPrimary
          onClick={onAccept}
          sx={{ height: '36px' }}>
          Share
        </ButtonContainedPrimary>
      </Stack>
    );
  }

  return (
    <MiniDialog
      title='Share Restricted Asset'
      open={open}
      close={onClose}
      customBottomButtons={() => renderButtons()}
      childrenSx={{ p: 3 }}
      titleSx={{
        mt: 2,
        py: 3,
      }}>
      <Box>
        <BodyOneSecondary>
          You are about to share a restricted asset. Please make sure the recipients have access to this asset before sharing it.
        </BodyOneSecondary>
      </Box>
    </MiniDialog>
  );
}
