/* eslint-disable @typescript-eslint/naming-convention */

import { Section } from '../../components-molecules/section/Section';
import { BodyTwoSecondary, CaptionPrimary, HeaderSix } from '../../components-atoms/TypographyComponents';
import { Grid, IconButton, Link, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LIGHT_THEME } from '../../constants/theme';
import '../../scss/components/_industry-insight.scss';
import { useState } from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShareIcon from '@mui/icons-material/Share';
import imageIcon from '../../assets/icons/Imagefile.svg';

interface IProps {
  // TODO: any
  product: any;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onPublishChange: (id: string) => void;
  onShare: (id: string, isRestricted: boolean) => void;
  moveUp: (ids: number) => void;
  moveDown: (idx: number) => void;
  onRestrictedClick?: (id: string) => void;
  index: number;
  count: number;
  showControls?: boolean;
}
export function DigitalToolkitListCard({ product, index, onEdit, onDelete, onPublishChange, onShare, moveUp, moveDown, onRestrictedClick, count, showControls }: IProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [imgError, setImgError] = useState(false);

  function renderActionButton() {
    if (showControls) {
      return (
        <Grid item container xs={4} justifyContent="flex-end">
          <IconButton sx={{ margin: '0 -18px 0 0', padding: 0, height: '48px', width: '48px' }} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      );
    }

    return (
      <Grid item container xs={4} justifyContent="flex-end">
        <IconButton
          sx={{ margin: '0 -18px 0 0', padding: 0, height: '48px', width: '48px' }}
          onClick={() => {
            onShare(product.Id, product.IsRestricted);
          }}>
          <ShareIcon />
        </IconButton>
      </Grid>
    );
  }

  function sortButtons() {
    if (!showControls) {
      return <Grid xs={8} container item direction="column" justifyContent="flex-end" alignItems="flex-end" sx={{ width: '100%' }}></Grid>;
    }

    return (
      <Grid xs={8} container item direction="column" justifyContent="flex-end" alignItems="flex-end" sx={{ width: '100%' }}>
        <IconButton
          data-testid="editors-pick-move-up-button"
          onClick={() => moveUp(index)}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          disabled={index === 0 || !product.Published}
        >
          <KeyboardArrowUpIcon color={
            index === 0 || !product.Published
              ? 'disabled'
              : 'primary'
          } />
        </IconButton>
        <IconButton
          data-testid="editors-pick-move-down-button"
          onClick={() => moveDown(index)}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          disabled={index === count - 1 || !product.Published}
        >
          <KeyboardArrowDownIcon color={
            index === count - 1 || !product.Published
              ? 'disabled'
              : 'primary'
          } />
        </IconButton>
      </Grid>
    );
  }

  return (
    <Section fullPadding>
      <Grid container item direction="row" columnSpacing={3} sx={{ width: '100%' }}>
        <Grid
          container
          justifyContent="center"
          item xs={2}
        >
          <Grid
            container
            justifyContent="center"
            item
            sx={{
              aspectRatio: '1/1',
              height: '96px',
              width: '96px',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              borderRadius: '4px',
              objectFit: 'contain'
            }}>
            <Link target="_blank" href={`/detail/toolkit/${product.Id}`}>
              <img
                style={{ maxHeight: '100%', maxWidth: '100%', }}
                src={imgError ? imageIcon : product.ThumbnailUrl || product.FileUrl}
                onError={() => setImgError(true)}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid container item xs={7} direction="column">
          <BodyTwoSecondary> {product.Published ? `Toolkit ${index + 1}` : 'Unlisted'}</BodyTwoSecondary>
          <Link target="_blank" href={`/detail/toolkit/${product.Id}`}>
            <HeaderSix sx={{ transition: '0.2s all', marginBottom: 0, color: LIGHT_THEME.palette.info.dark, '&:hover': { textDecoration: 'underline' } }} >{product.Title}</HeaderSix>
          </Link>
        </Grid>
        <Grid xs={1} container item justifyContent="center" alignItems="center" padding="24px 0">
          <CaptionPrimary fontWeight={700} sx={{ color: product.Published ? LIGHT_THEME.palette.success.main : LIGHT_THEME.palette.error.main }}>
            {product.Published ? 'Published' : 'Unpublished'}
          </CaptionPrimary>
          {product.VisibilityRestrictions
            ? <CaptionPrimary onClick={() => onRestrictedClick?.(product.Id)} fontWeight={700} sx={{ cursor: 'pointer', color: LIGHT_THEME.palette.warning.main }}>
              Restricted
            </CaptionPrimary>
            : null}
        </Grid>
        <Grid xs={2} container item justifyContent="center" alignItems="center" direction="row">
          {sortButtons()}
          {renderActionButton()}
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiPaper-root': { width: '160px', marginTop: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => { onEdit(product.Id); handleClose(); }}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          onShare(product.Id, product.IsRestricted);
          handleClose();
        }}>
          <ListItemText>Share</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { onPublishChange(product.Id); handleClose(); }}>
          <ListItemText >{!product.Published ? 'Publish' : 'Unpublish'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { onDelete(product.Id); handleClose(); }}>
          <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Section>
  );
}

