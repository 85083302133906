/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { Box, Autocomplete, TextField, Stack, Grid, Typography, IconButton, Divider, styled } from '@mui/material';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';

import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';
import { BodyOneSecondary, OverlineSecondary } from '../../components-atoms/TypographyComponents';

import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { GetRestrictionList } from '../../service/adminService';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { GetAllServicesRequest } from '../../service/serviceService';
import Edit from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export enum IRestrictionUserAction {
  Add = 'Add',
  Update = 'Update'
}

export enum IRestrictionUserSource {
  BrandAssets = 'Brand Assets',
  DigitalToolkits = 'Digital Toolkits'
}

const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ isSelected }) => ({
  '.MuiFilledInput-root': {
    padding: isSelected && '36px 8px 8px 8px'
  }
}));

export interface IPortalManagerRecipientProps {
  title?: string;
  description?: string;
  onChange?: (data: any) => void;

  service: IRestrictionUserSource;
  action?: IRestrictionUserAction;
  selectedAudiences?: any;
  selectedMerchants?: any;
}

export default function RestrictionUsers(props: IPortalManagerRecipientProps): JSX.Element {
  const { action = IRestrictionUserAction.Add, title, service, onChange } = props;
  const token = useTypedSelector(state => state.userReducer.token);
  const [dialog, setDialog] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [merchants, setMerchants] = useState([]);

  const [tempAudiences, setTempAudiences] = useState<any>(props.selectedAudiences || []);
  const [tempMerchants, setTempMerchants] = useState<any>(props.selectedMerchants || []);

  const [selectedAudiences, setSelectedAudiences] = useState<any>(props.selectedAudiences || []);
  const [selectedMerchants, setSelectedMerchants] = useState<any>(props.selectedMerchants || []);

  useEffect(() => {
    if (dialog) {
      console.log('Props', props);
      setTempAudiences(props.selectedAudiences || []);
      setTempMerchants(props.selectedMerchants || []);
      // setSelectedAudiences(props.selectedAudiences || []);
      // setSelectedMerchants(props.selectedMerchants || []);
    }
  }, [dialog]);

  useEffect(() => {
    if (token) {
      GetAllServicesRequest(
        token,
        (res) => {
          const services = res.Result.map((c) => c.Services);
          const selected = services.flat()
            .find((c) => (service === IRestrictionUserSource.BrandAssets && c.ServiceName === 'Brand Assets')
              || (service === IRestrictionUserSource.DigitalToolkits && c.ServiceName === 'Digital Toolkits'));
          getRestrictionList(token, selected?.ServiceId);
        },
        (err) => { console.log('Err', err); }
      );

    }
  }, [token, service]);

  const getRestrictionList = (token: string, serviceId?: GUID | string) => {
    if (!serviceId) return;

    GetRestrictionList(
      token,
      serviceId,
      (response) => {
        setAudiences(response.AudienceUserRole);
        setMerchants(response.MerchantUserRole);
      },
      (error) => { console.error(error); }
    );
  };

  const onSubmit = () => {
    setSelectedAudiences(tempAudiences);
    setSelectedMerchants(tempMerchants);
    onChange?.({
      selectedAudiences: tempAudiences,
      selectedMerchants: tempMerchants
    });
    setDialog(false);
  };

  const onClose = () => {
    setDialog(false);
  };

  const addRecipientsClicked = () => {
    setDialog(true);
  };

  const removeRecipientItem = (type: any, r: any) => {
    let newMerchants = selectedMerchants;
    let newAudiences = selectedAudiences;

    switch (type) {
      case 'Audience / User Group':
        newAudiences = selectedAudiences.filter((m: any) => m.AudienceId !== r.AudienceId || m.UserRoleId !== r.UserRoleId);
        setSelectedAudiences(newAudiences);
        break;
      case 'Merchant / User Group':
        newMerchants = selectedMerchants.filter((m: any) => m.MerchantId !== r.MerchantId || m.UserRoleId !== r.UserRoleId);
        setSelectedMerchants(newMerchants);
        break;
    }

    onChange?.({
      selectedAudiences: newAudiences,
      selectedMerchants: newMerchants
    });
  };

  const noRecipientSelected = () => {
    return (
      <Box mt={2}>
        <InfoComponent
          title={`${service} Restrictions`}
          description="You haven't added any restrictions to this asset yet. To begin, select audiences or merchants."
        />
      </Box>
    );
  };

  const renderRecipientItem = (type: any, r: any, isLast: boolean) => {
    return (
      <Fragment key={r.DisplayName + r.UserRoleId}>
        <Grid container flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap={{ xs: 'nowrap', sm: 'unset' }} sx={{ flexShrink: 1, flexGrow: 1, minHeight: '80px' }}>
          <Grid item xs={3.2} sx={{ width: { xs: '100%' } }}>
            <OverlineSecondary>{type}</OverlineSecondary>
          </Grid>

          <Grid item xs>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography>{r.DisplayName}</Typography>
              <IconButton onClick={() => removeRecipientItem(type, r)}>
                <RemoveCircleIcon color="primary" fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        {!isLast ? <Divider /> : null}
      </Fragment>
    );
  };

  const renderRecipients = () => {
    return (
      <Box>
        {(selectedAudiences && selectedAudiences.length > 0)
          && <Box mt={2}>
            <SectionHeader title="Restricted Audiences" />
            {selectedAudiences.map((sm: any, idx: number) => (
              renderRecipientItem('Audience / User Group', sm, idx === selectedAudiences.length - 1)
            ))}
          </Box>
        }

        {(selectedMerchants && selectedMerchants.length > 0)
          && <Box mt={2}>
            <SectionHeader title="Restricted Merchants" />
            {selectedMerchants.map((sm: any, idx: number) => (
              renderRecipientItem('Merchant / User Group', sm, idx === selectedMerchants.length - 1)
            ))}
          </Box>
        }
      </Box>
    );
  };

  return (
    <ModalCardComponent>
      <SectionHeader
        title={title || 'Restrict Visibility'}
        button={
          <ButtonContainedPrimary
            data-testid="restrict-visibility"
            onClick={addRecipientsClicked}
            startIcon={<Edit />}
          >
            {action === IRestrictionUserAction.Add ? 'Add' : 'Update'} Visibility Restrictions
          </ButtonContainedPrimary>
        }
      />

      {renderRecipients()}

      {selectedAudiences && selectedAudiences.length === 0 && selectedMerchants && selectedMerchants.length === 0
        ? noRecipientSelected() : null
      }

      <MiniDialog
        title={`${action === IRestrictionUserAction.Add ? 'Add' : 'Update'} Visibility Restrictions`}
        open={dialog}
        close={onClose}
        submit={onSubmit}>
        <Box sx={{ padding: '0 24px' }}>
          <BodyOneSecondary>
            Set a combination of Merchant Audience and User role to grant users access to this content.
          </BodyOneSecondary>

          <Autocomplete
            multiple
            disableCloseOnSelect
            options={audiences}
            value={tempAudiences}
            getOptionLabel={(option: any) => option.DisplayName}
            onChange={(_, val: any) => {
              const selected = val.filter((value: any, index: number, self: any) =>
                index === self.findIndex((t: any) => (
                  t.DisplayName === value.DisplayName
                  && t.AudienceId === value.AudienceId
                  && t.UserRoleId === value.UserRoleId
                ))
              );

              setTempAudiences(selected);
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant='filled'
                label="Audience / User Group"
                placeholder="Audience / User Group"
                sx={{ mt: 3, mb: 2 }}
                isSelected={tempAudiences?.length > 0}
              />
            )}
          />

          <Autocomplete
            multiple
            disableCloseOnSelect
            options={merchants}
            value={tempMerchants}
            getOptionLabel={(option: any) => option.DisplayName}
            onChange={(_, val: any) => {
              const selected = val.filter((value: any, index: number, self: any) =>
                index === self.findIndex((t: any) => (
                  t.DisplayName === value.DisplayName
                  && t.AudienceId === value.AudienceId
                  && t.UserRoleId === value.UserRoleId
                ))
              );

              setTempMerchants(selected);
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant='filled'
                label="Merchant / User Group"
                placeholder="Merchant / User Group"
                isSelected={tempMerchants?.length > 0}
              />
            )}
          />
        </Box>
      </MiniDialog>
    </ModalCardComponent>
  );
}
