import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface InfoComponentProps {
  title: string;
  description: string;
  severity?: 'success' | 'info' | 'warning' | 'error';
}

export function InfoComponent({ title, description, severity }: InfoComponentProps): JSX.Element {
  return (
    <Alert severity={severity || 'info'} sx={{ borderRadius: '4px', width: '100%' }}>
      <AlertTitle>{title}</AlertTitle>
      {description}
    </Alert>
  );
}
