import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { Fragment, useEffect, useState } from 'react';
import { CircularProgress, Divider, Grid, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { gaService } from '../../service/gaService';
import { GetFocusOfTheMonthDetail } from '../../service/serviceService';
import DetailPageHeader from '../../components-organisms/appbars/DetailPageHeader';
import { ListingCard } from '../../components-organisms/cards/listing/ListingCard';
import { BodyTwoSecondary, HeaderSix } from '../../components-atoms/TypographyComponents';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Footer } from '../../components-organisms/Footer';

export function FocusOfTheMonthDetail(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [token, userServices] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.services]);
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState();

  const getFocusOfTheMonth = () => {
    if (token && id) {
      setLoading(true);
      GetFocusOfTheMonthDetail(
        token,
        id,
        (res: any) => {
          setData(res.Result[0]);
          setLoading(false);
        },
      );
    }
  };

  useEffect(() => {
    if (!state?.product) {
      getFocusOfTheMonth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gaService.pageView('Focus of the Month Detail');
  }, []);

  const onBack = () => {
    const digitalAssetServiceId = userServices.flatMap(f => f.Services).find(m => m.ServiceName === 'Focus of the Month')?.ServiceId;
    const serviceUrl = '/service/';
    if (digitalAssetServiceId) {
      navigate(`${serviceUrl}${digitalAssetServiceId}`);
      return;
    }

    navigate(-1);
  };

  if (loading || !data) {
    return (
      <CircularProgress
        color='primary'
        size={50}
        style={{ zIndex: 98, position: 'absolute', top: '50vh', right: '50vw' }}
      />
    );
  }

  function renderHeader() {
    return (
      <DetailPageHeader
        title="Focus of the Month"
        onBack={onBack} />
    );
  }

  function renderAttachments() {
    if (data?.Attachments?.length > 0) {
      return (
        <>
          <HeaderSix sx={{ my: 3 }}>File Attachments</HeaderSix>
          {data.Attachments.map((attachment: any, index: number) => (
            <Fragment key={index}>
              <Grid container alignItems="center" flexWrap="nowrap" sx={{ margin: '16px 0' }}>
                <AttachFileIcon color="primary" sx={{ cursor: 'pointer' }} onClick={() => window.open(attachment.AttachmentUrl)} />
                <BodyTwoSecondary
                  sx={{
                    color: '#012169',
                    cursor: 'pointer',
                    marginLeft: '16px',
                    width: '100%'
                  }}
                  onClick={() => window.open(attachment.AttachmentUrl)}
                >
                  {attachment.AttachmentName}
                </BodyTwoSecondary>
              </Grid>
              {index !== data.Attachments.length - 1 && (
                <Divider style={{ width: 'calc(100% - 40px)', marginLeft: '40px' }} />
              )}
            </Fragment>
          ))}
        </>
      );
    }
  }

  function renderContent() {
    return (
      <Stack
        display='flex'
        flex={1}
        mt={4}
        px={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}>
        <ListingCard
          hideEllipsis
          fromDetail
          thumbnailFit='cover'
          isFirst={true}
          isLast={true}
          title={data.Title}
          description={data.Description}
          thumbnailUrl={data.ThumbnailUrl}
          content={renderAttachments} />
      </Stack>
    );
  }

  return (
    <Stack flex={1}>
      {renderHeader()}
      {renderContent()}
      
      <Grid item sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Stack>
  );
}
