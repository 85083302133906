/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import { CircularProgress, FormHelperText, Stack, Divider, Typography, IconButton } from '@mui/material';

import { SalesManagerFormTypes, SALES_MANAGER_FORM } from '../../forms/salesManager';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { GetSalesManagerRoles, SubmitSalesManager } from '../../service/adminService';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { IStyles } from '../../constants/theme';
import { OverlineSecondary } from '../../components-atoms/TypographyComponents';
import SaveChanges from './SaveChanges';
import { TerritoryManagerAssignModal } from '../TerritoryManagerAssignModal';
import { SubmitAssignMerchantsRequestModel, SubmitAssignTerritoryManagerRequestModel } from '../../service/merchantsService';
import { CustomSubmitModel, MerchantAssignModal } from '../MerchantAssignModal';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { GetStoreListModel, GetStoreListRequest } from '../../service/storeService';

interface AssignedTerritoryManagers {
  requestModel: SubmitAssignTerritoryManagerRequestModel;
  displayNames: { id: string, name: string; }[];
}

interface AssignedMerchants {
  requestModel: CustomSubmitModel;
  displayNames: { id: string; type: string; name: string; }[];
}

export function AddSalesManager(): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const { control, handleSubmit, formState: { isValid, isDirty }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const navigate = useNavigate();

  const [assignedMerchants, setAssignedMerchants] = useState<AssignedMerchants>();
  const [assignedTerritoryManagers, setAssignedTerritoryManagers] = useState<AssignedTerritoryManagers>();

  const [storeNames, setStoreNames] = useState<GetStoreListModel[]>([]);

  useEffect(() => {
    if (token) {
      GetStoreListRequest(
        token,
        (response) => {
          if (response) {
            setStoreNames(response);
          }
        },
        () => {
          setStoreNames([]);
        }
      );
    }
  }, [token]);

  const assignTerritoryManagers = (requestModel: SubmitAssignTerritoryManagerRequestModel, displayNames: { id: string; name: string; }[]) => {
    setAssignedTerritoryManagers({ requestModel, displayNames });
    setValue('AssignedTerritoryManagers', JSON.stringify(requestModel), { shouldDirty: true });
  };

  const assignMerchants = (requestModel: CustomSubmitModel, displayNames: { id: string; type: string; name: string; }[]) => {
    const requestModel2: CustomSubmitModel = {
      MerchantIds: requestModel.MerchantIds,
      StoreIds: requestModel.StoreIds
    };

    setAssignedMerchants({ requestModel: requestModel2, displayNames });
    setValue('AssignedMerchants', JSON.stringify(requestModel), { shouldDirty: true });
  };

  const convertCustomSubmitModelToRequestModel = (customSubmitModel?: CustomSubmitModel): SubmitAssignMerchantsRequestModel | undefined => {
    if (customSubmitModel === undefined || (customSubmitModel.MerchantIds === undefined && customSubmitModel.StoreIds === undefined)) {
      return undefined;
    }

    const requestModel: SubmitAssignMerchantsRequestModel = {
      MerchantIds: customSubmitModel.MerchantIds,
      StoreIds: customSubmitModel.StoreIds ? Object.entries(customSubmitModel.StoreIds).map(x => x[1]).flat() : undefined
    };

    return requestModel;
  };

  const unLinkTM = (index: number) => {
    const id = assignedTerritoryManagers?.requestModel.TerritoryManagerIds[index];
    delete assignedTerritoryManagers?.requestModel.TerritoryManagerIds[index];
    const newDisplayNames = assignedTerritoryManagers?.displayNames.filter(x => x.id !== id) || [];

    assignTerritoryManagers({
      TerritoryManagerIds: assignedTerritoryManagers?.requestModel.TerritoryManagerIds.map(t => t) || []
    }, newDisplayNames);
  };

  const unlinkMerchantorStore = (id: string, type: string) => {
    let MerchantIds = assignedMerchants?.requestModel.MerchantIds;
    let StoreIds = assignedMerchants?.requestModel.StoreIds;
    const newDisplayNames = assignedMerchants?.displayNames || [];

    switch (type) {
      case 'Merchant':
        if (MerchantIds) {
          const merchantIdIndex = MerchantIds.findIndex(x => x === id as GUID);
          MerchantIds.splice(merchantIdIndex, 1);
          newDisplayNames.splice(newDisplayNames.findIndex(x => x.id === id), 1);
        }
        break;
      case 'Store':
        if (StoreIds) {
          // find merchant id for store id in StoreIds
          let merchantId: string | undefined;
          Object.entries(StoreIds).map(([key, value]) => {
            if (value.includes(id as GUID)) {
              merchantId = key;
            }
          });

          if (merchantId) {
            const storeIdIndex = StoreIds[merchantId].findIndex(x => x === id);
            StoreIds[merchantId].splice(storeIdIndex, 1);
            newDisplayNames.splice(newDisplayNames.findIndex(x => x.id === id), 1);

            if (StoreIds[merchantId].length === 0) {
              delete StoreIds[merchantId];
            }
          }
        }
        break;
    }

    if (MerchantIds && MerchantIds.length === 0) {
      MerchantIds = undefined;
    }

    if (StoreIds && Object.keys(StoreIds).length === 0) {
      StoreIds = undefined;
    }

    assignMerchants({
      MerchantIds,
      StoreIds
    }, newDisplayNames);
  };

  useEffect(() => {
    if (token) {
      setOptionsLoading(true);
      GetSalesManagerRoles(
        token,
        (response) => {
          setOptionsLoading(false);
          response.Result.map(res => {
            setOptions(((prev: any) => [...prev, { value: res.Id, name: res.Name }]));
          });
        },
        () => {
          setOptionsLoading(false);
        }
      );
    }
  }, [token]);

  useEffect(() => {
    setValue('published', true, { shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit: SubmitHandler<SalesManagerFormTypes> = (data): void => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);
      SubmitSalesManager(
        token,
        {
          FirstName: data.firstName,
          LastName: data.lastName,
          Phone: data.phone,
          Email: data.email,
          SalesManagerRoleId: data.role,
          AssignedMerchants: convertCustomSubmitModelToRequestModel(assignedMerchants?.requestModel),
          AssignedTerritoryManagers: assignedTerritoryManagers?.requestModel
        },
        () => {
          navigate('/admin/salesmanagers', { state: { from: 'add-salesmanager' } });
        },
        () => {
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const handleClose = () => {
    if (isDirty) {
      setShowSaveModal(true);
      return;
    }

    navigate('/admin/salesmanagers', { state: { from: 'add-salesmanager' } });
  };

  const onSaveChangesSubmit = () => {
    setShowSaveModal(false);
    navigate('/admin/salesmanagers', { state: { from: 'add-salesmanager' } });
  };

  const getModalStoresAndMerchantsList = () => {
    const list: { id: GUID; type: string; name: string; }[] = [];

    if (assignedMerchants && assignedMerchants.requestModel.MerchantIds) {
      assignedMerchants.requestModel.MerchantIds.map((merchantId) => {
        list.push({ id: merchantId as GUID, type: 'Merchant', name: assignedMerchants.displayNames.find(x => x.id === merchantId)?.name || '' });
      });
    }

    if (assignedMerchants && assignedMerchants.requestModel.StoreIds) {
      Object.entries(assignedMerchants.requestModel.StoreIds).map(([, storeIds]) => {
        storeIds.map((storeId) => {
          const store = storeNames.find(x => x.StoreId === storeId);
          const storeName = store?.StoreName;
          const merchantName = store?.MerchantName;
          list.push({ id: storeId, type: 'Store', name: `${merchantName} — ${storeName}` });
        });
      });
    }

    return list;
  };

  const hasAssignedStoresOrMerchants = (assignedMerchants && assignedMerchants.requestModel.MerchantIds && assignedMerchants.requestModel.MerchantIds.length > 0)
    || (assignedMerchants && assignedMerchants.requestModel.StoreIds && Object.keys(assignedMerchants.requestModel.StoreIds).length > 0);

  const renderAssignment = (index: number, id: string, type: string, name: string | undefined, isLast: boolean) => {
    return (
      <Fragment key={index}>
        <Grid container flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap={{ xs: 'nowrap', sm: 'unset' }} sx={{ flexShrink: 1, flexGrow: 1, minHeight: '80px' }}>
          <Grid item xs={3.2} sx={{ width: { xs: '100%' } }}>
            <OverlineSecondary>{type}</OverlineSecondary>
          </Grid>

          <Grid item xs>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography>{name}</Typography>
              <IconButton onClick={() => type === 'Territory Manager' ? unLinkTM(index) : unlinkMerchantorStore(id, type)}>
                <RemoveCircleIcon
                  color='primary'
                  fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        {!isLast ? <Divider /> : null}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {optionsLoading ? (
        <Grid container item justifyContent="center" alignItems="center">
          <CircularProgress
            color="primary"
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>
      ) : (
        <FormPageComponent
          title="Add Sales Manager"
          buttonTitle="Add Manager"
          buttonDisabled={!isValid || loading}
          buttonLoading={loading}
          close={handleClose}
          submit={handleSubmit(submit)}
          scrollToTop={scrollToTop}
        >
          <Fragment>
            {SALES_MANAGER_FORM.map((section, index) => {
              return (
                <ModalCardComponent key={index}>
                  <SectionHeader title={section.title} />
                  <Fragment>
                    {section.fields.map((field, idx) =>
                      <Grid key={idx}>
                        {field.inputType === 'select'
                          ? <FormControlSelect control={control} field={{ ...field, options }} />
                          : <FormControlInput control={control} field={field} />
                        }
                      </Grid>
                    )}
                    <div style={styles.helperTextContainer}>
                      <FormHelperText>
                        Example: 07975777666
                      </FormHelperText>
                    </div>
                  </Fragment>
                </ModalCardComponent>
              );
            })}

            <ModalCardComponent>
              <SectionHeader
                title='Assign Merchant or Stores'
                button={<MerchantAssignModal
                  customTitle='Manage Assignments'
                  onCustomSubmit={assignMerchants}
                  initialSelected={{
                    Merchants: (assignedMerchants?.requestModel.MerchantIds || []).map(x => x as GUID),
                    Stores: assignedMerchants?.requestModel.StoreIds
                  }} />}
              />

              {hasAssignedStoresOrMerchants
                ? <Fragment>
                  {getModalStoresAndMerchantsList().map((obj, index) =>
                    renderAssignment(
                      index,
                      obj.id,
                      obj.type,
                      obj.name,
                      index === assignedMerchants.displayNames.length - 1
                    )
                  )}
                </Fragment>
                : <InfoComponent title="" description="No Merchants or Stores are assigned" />
              }

            </ModalCardComponent>

            <ModalCardComponent>
              <SectionHeader
                title='Assign Territory Managers'
                button={<TerritoryManagerAssignModal
                  customTitle='Manage Assignments'
                  onCustomSubmit={assignTerritoryManagers}
                  initialSelected={assignedTerritoryManagers?.requestModel.TerritoryManagerIds}
                />}
              />

              {assignedTerritoryManagers && assignedTerritoryManagers.requestModel.TerritoryManagerIds.length > 0
                ? <Fragment>
                  {assignedTerritoryManagers.requestModel.TerritoryManagerIds.map((id, index) =>
                    renderAssignment(
                      index,
                      id,
                      'Territory Manager',
                      assignedTerritoryManagers.displayNames.find(x => x.id === id)?.name,
                      index === assignedTerritoryManagers.requestModel.TerritoryManagerIds.length - 1
                    )
                  )}
                </Fragment>
                : <InfoComponent title="" description="No Territory Managers are assigned" />
              }
            </ModalCardComponent>
          </Fragment>
          {loading ? (
            <div style={styles.overlay}>
            </div>
          ) : (
            <></>
          )}
        </FormPageComponent>
      )}

      <SaveChanges
        visible={showSaveModal}
        onCancel={() => setShowSaveModal(false)}
        onSubmit={onSaveChangesSubmit}
      />
    </Fragment>
  );
}

const styles: IStyles = {
  helperTextContainer: {
    padding: '0 14px'
  },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
