import { AxiosError } from 'axios';

import { HttpRequestDelete, HttpRequestGet, HttpRequestPost, HttpRequestPut } from './genericService';

interface NewStoreRequestModel {
  StoreName: string;
  MerchantId: GUID;
  CustomerCode: string;
  AddressLine1: string;
  AddressLine2: undefined | string;
  City: string;
  PostCode: string;
  County: string;
  Phone: string;
  Email: undefined | string;
  TerritoryManagerId: GUID | undefined;
  SalesManagerIds: string[];
}

export function AddStoreRequest(token: string, body: NewStoreRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, NewStoreRequestModel>(
    '/store',
    body,
    (response) => success(response.data),
    (error: AxiosError) => failure(error),
    token
  );
}
export function DeleteStoreRequest(token: string, guid: GUID, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete(
    `/store/${guid}`,
    () => success(),
    (error: AxiosError) => failure(error),
    token
  );
}

interface StoreEditModel {
  Id: string;
  Name: string;
  CustomerCode: string;
  AddressLine1: string;
  AddressLine2?: string;
  City: string;
  PostCode: string;
  County: string;
  Phone: string;
  Email: string;
  TerritoryManagerId?: GUID | null;
  SalesManagerIds: string[];
}
export function EditStoreRequest(token: string, body: StoreEditModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPut<unknown, StoreEditModel>(
    '/store/edit',
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    token
  );
}

type StoreAssignRequestModel = {
  UserId: GUID;
  StoreId: GUID;
};
export function AssignToStoreRequest(token: string, body: StoreAssignRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<unknown, StoreAssignRequestModel>(
    '/store/assign',
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    token
  );
}

export function UnassignStoreRequest(token: string, storeId: string, userId: string, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete(
    `/store/assign/${storeId}/${userId}`,
    () => success(),
    (error) => failure(error),
    token
  );
}

export interface GetStoreListModel {
  StoreId: string;
  StoreName: string;
  MerchantId: string;
  MerchantName: string;
}

export function GetStoreListRequest(token: string, success: (response: GetStoreListModel[]) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetStoreListModel[]>(
    '/store/list',
    (response) => success(response.data.Result),
    (error) => failure(error),
    token
  );
}
