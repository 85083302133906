import { AxiosError } from 'axios';
import { HttpRequestPost } from './genericService';

export interface AnalyticsFilterModel {
  AssetTypes?: string[]
  DateFrom?: string
  DateTo?: string
  SharerNames?: string[]
  SharerRoles?: string[]
  RecipientNames?: string[]
  RecipientRoles?: string[]
  RecipientEmails?: string[]
  RecipientMerchants?: string[]
  HasOpened?: boolean
  HasDownloaded?: boolean
}

interface ShareAnalyticsRequestModel {
  Query?: string;
  Size?: number;
  Page?: number;
  OrderBy?: 'asc' | 'desc';
  Filters?: AnalyticsFilterModel
}

export interface ShareAnalyticsRowModel {
  DateOfShare: string;
  AssetType: string;
  AssetName: string;
  SharerName: string;
  SharerRole: string;
  RecipientName: string;
  RecipientEmail: string;
  RecipientRole: string;
  RecipientMerchant: string;
  HasOpened: boolean;
  HasDownloaded: boolean;
}

interface ShareAnalyticsResponseModel {
  ApplicableFilters: AnalyticsFilterModel;
  Rows: ShareAnalyticsRowModel[];
  Count: number;
}

export function GetShareAnalytics(
  token: string,
  body: ShareAnalyticsRequestModel,
  success: (response: ShareAnalyticsResponseModel) => void,
  failure?: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<ShareAnalyticsResponseModel, ShareAnalyticsRequestModel>(
    '/analytics/share',
    body,
    (response) => success(response.data.Result),
    (error) => failure && failure(error),
    token,
  );
}
