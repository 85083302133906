/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { Typography, IconButton, Divider, Grid, Stack, Box, Tooltip } from '@mui/material';
import { OverlineSecondary, BodyTwoSecondary } from '../../components-atoms/TypographyComponents';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { SalesManagerModal } from './SalesManagerModal';
import { GetSalesManagersRequest } from '../../service/adminService';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface ISalesManagersListProps {
  managers?: SalesManager[];
  onChange?: (managers: any[]) => void;
}

export default function SalesManagersList(props: ISalesManagersListProps): JSX.Element {
  const { managers, onChange } = props;
  const dispatch = useTypedDispatch();
  const [token, salesManagers] = useTypedSelector(state => [state.userReducer.token, state.salesManagersReducer.data]);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSalesManagers, setSelectedSalesManagers] = useState<any>([]);
  const [filteredSearchManagers, setFilteredSearchManagers] = useState<any>([]);

  const getSalesManagers = () => {
    if (token) {
      dispatch({ type: 'SET_SALES_MANAGERS_LOADING', payload: { currentData: true } });
      GetSalesManagersRequest(
        token,
        { Query: modalOpen ? searchQuery : '' },
        (response) => {
          if (modalOpen && searchQuery && searchQuery.length > 0) {
            setFilteredSearchManagers(response);
            return;
          }
          dispatch({ type: 'SET_SALES_MANAGERS', payload: { currentData: response } });
          dispatch({ type: 'SET_SALES_MANAGERS_LOADING', payload: { currentData: false } });
          setFilteredSearchManagers(response);
          mapSalesManagers(response);
        },
        (error) => { console.error(error); }
      );
    }
  };

  useEffect(() => {
    getSalesManagers();
  }, [searchQuery]);

  useEffect(() => {
    if (selectedSalesManagers.filter((s: any) => s).length > 0) {
      onChange?.(selectedSalesManagers.map((s: any) => s.Id));
    }
  }, [selectedSalesManagers]);

  const mapSalesManagers = (response: any = salesManagers, selected: any = managers) => {
    if (selected && selected.length > 0) {
      const mappedSalesManagers = selected?.map((r: any) => {
        const item = response.find((m: any) => m.SalesManager.AdminProperties.Id === r.Id)?.SalesManager?.AdminProperties;
        if (item) {
          return {
            ...item,
            LinkType: r.LinkType,
          };
        }
      }).filter((sm: any) => sm);

      setSelectedSalesManagers(mappedSalesManagers);
    }
  };

  const addSalesManager = (ids: Set<string>): void => {
    mapSalesManagers(undefined, ids);
    setModalOpen(false);
  };

  const onSearchChange = (text: string) => {
    setSearchQuery(text);
  };

  const onRemove = (id: string) => {
    const filtered = selectedSalesManagers.filter((r: any) => r.Id !== id);
    mapSalesManagers(undefined, filtered);
  };

  const renderNoSalesManagers = () => {
    return (
      <Grid item sx={{ flexGrow: 1, margin: '16px 0' }}>
        <InfoComponent
          title="Adding Sales Managers"
          description={[].length > 0 ? 'Add Sales Managers to the store.' : 'You haven\'t added any employees to this merchant yet.'}
        />
      </Grid>
    );
  };

  const renderSalesManagers = () => {
    return (
      <Box>
        {selectedSalesManagers?.map((r: any, index: number) => renderSalesManagerItem(r, index === [].length - 1))}
      </Box>
    );
  };

  const renderSalesManagerItem = (r: any, isLast?: boolean) => {
    const isDisabled = r?.LinkType !== 'Store';
    const Component = isDisabled ? Tooltip : Fragment;
    return (
      <Fragment key={r.Id}>
        <Grid container flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap={{ xs: 'nowrap', sm: 'unset' }} sx={{ flexShrink: 1, flexGrow: 1, minHeight: '80px' }}>
          <Grid item xs={3.2} sx={{ width: { xs: '100%' } }}>
            <OverlineSecondary>Sales Manager</OverlineSecondary>
          </Grid>

          <Grid item xs>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack>
                <Typography>{r.FirstName} {r.LastName}</Typography>
                <BodyTwoSecondary>
                  {r.Email}
                </BodyTwoSecondary>
                <BodyTwoSecondary>
                  {r.LinkType}
                </BodyTwoSecondary>
              </Stack>
              <Component title={'You can‘t unlink a Sales Manager with this type.'}>
                <IconButton onClick={() => isDisabled ? undefined : onRemove(r.Id)}>
                  <RemoveCircleIcon
                    color={isDisabled ? 'disabled' : 'primary'}
                    fontSize="small" />
                </IconButton>
              </Component>
            </Stack>
          </Grid>
        </Grid>
        {!isLast ? <Divider /> : null}
      </Fragment>
    );
  };

  return (
    <ModalCardComponent>
      <SectionHeader
        title="Sales managers"
        button={
          <ButtonContainedPrimary disabled={salesManagers?.length === 0} startIcon={<AddIcon />} onClick={() => setModalOpen(true)}>Link Managers</ButtonContainedPrimary>
        }
      />

      {(selectedSalesManagers && selectedSalesManagers.length > 0) ? renderSalesManagers() : renderNoSalesManagers()}

      <SalesManagerModal
        salesManagers={filteredSearchManagers}
        open={modalOpen}
        close={() => setModalOpen(false)}
        submit={addSalesManager}
        onSearchChange={onSearchChange}
        selected={selectedSalesManagers}
      />
    </ModalCardComponent>
  );
}
