/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Modal, Paper, Typography } from '@mui/material';

const style: any = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: 480,
  borderRadius: 2,
  padding: '16px 24px'
};

export interface ISaveChangesProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function SaveChanges(props: ISaveChangesProps): JSX.Element {
  const { visible, onCancel, onSubmit } = props;

  return (
    <Modal
      open={visible}
      onClose={onCancel}
      disableAutoFocus={true}>
      <Paper
        elevation={24}
        sx={style}>
        <Typography id='modal-title' variant='h6' component='h2'>
          Leave Page?
        </Typography>
        <Typography id='modal-description' sx={{ mt: 2 }}>
          You have unsaved changes. If you leave this page, your changes will be lost.
        </Typography>

        <Box display="flex" alignSelf="flex-end" mt={4} gap={2}>
          <Button variant='text' onClick={onCancel}>Cancel</Button>
          <Button variant='outlined' color='error' onClick={onSubmit}>Leave</Button>
        </Box>
      </Paper>
    </Modal>
  );
}
